import { Button, Col, Dropdown, Row, Space, Tag } from 'antd';
import React, { useContext, useState } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../../../../../../AppContext';
import {
  CommentWhiteIcon,
  Import,
  InfoIcon,
} from '../../../../../../../assets/svg';
import {
  ALLOWED_ACTION_KEYS,
  ALLOWED_ACTION_TYPE,
  BREAKPOINTS,
  EQC_STATUS_CLASSNAME,
  EQC_STATUS_KEYS,
  EQC_STATUS_LABEL,
  GA_EVENT,
  GA_LABEL,
  REPORT_TYPE,
  UOMS,
} from '../../../../../../../common/constants';
import { Event } from '../../../../../../../common/trackEvents';
import useRouter from '../../../../../../../common/useRouter';
import { timeTaken } from '../../../../../../../common/utils';
import CanPerform from '../../../../../../../components/CanPerform';
import CommonCard from '../../../../../../../components/CommonCard';
import EllipsisText from '../../../../../../../components/EllipsisText';
import AddCommentModal from './AddCommentModal';
import AuditorCommentModal from './AuditorCommentModal';
import EqcRenameModal from './EqcRenameModal';

const EqcSummary = ({ data = {}, refetch, projectData }) => {
  const {
    projectEqcTypeName,
    eqcName,
    uom,
    status,
    eqcRenameLogs,
    id,
    isAudited,
    eqcCompletedAt,
    eqcStartedAt,
  } = data;
  const {
    params: { projectId },
  } = useRouter();
  const { getCurrentProjectDetail,getCurrentTenant,getTenantUser } = useContext(AppContext);
  const tenantId = getCurrentTenant() || getTenantUser()?.tenant?.id;
  const projectDetails = getCurrentProjectDetail();
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showAddCommentsModal, setShowAddCommentsModal] = useState(false);
  const [showAuditorCommentsModal, setShowAuditorCommentsModal] =
    useState(false);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const { getToken, getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();

  const CommentButtons = () => (
    <Row gutter={isDesktopViewport ? [12, 12] : [8, 8]} wrap={false}>
      <Col flex="auto">
        <Button
          shape="round"
          icon={<CommentWhiteIcon />}
          className={`comment-button ${
            isDesktopViewport ? '' : 'width-percent-100 d-flex justify-center'
          }`}
          onClick={() => {
            setShowAuditorCommentsModal(true);
            Event(GA_EVENT.VIEW_AUDITOR_COMMENT_MODAL, {
              label: GA_LABEL.VIEW_AUDITOR_COMMENT_MODAL,
              // eslint-disable-next-line no-undef
              pathname: window?.location?.href,
              project_id: projectId,
              project_eqc_id: id,
              user_id: currentUser?.id,
              user_name: currentUser?.name,
              tenant_id: currentUser?.tenantUser?.tenant?.id,
              tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
            });
          }}
          disabled={!isAudited}
        >
          Auditor Comments
        </Button>
      </Col>
      <CanPerform
        action={ALLOWED_ACTION_KEYS.ADD_AUDITOR_COMMENTS}
        type={ALLOWED_ACTION_TYPE.BOTH}
      >
        <Col span={!isDesktopViewport && 12}>
          <Button
            shape="round"
            icon={<CommentWhiteIcon />}
            className={`comment-button ${
              isDesktopViewport ? '' : 'width-percent-100 d-flex justify-center'
            }`}
            onClick={() => setShowAddCommentsModal(true)}
            disabled={!projectData?.getProject?.isActive}
          >
            Add Comments
          </Button>
        </Col>
      </CanPerform>
    </Row>
  );

  const ReportButtons = () => {
    const token = getToken();
    const url = `${process.env.REACT_APP_REPORT_SERVER_URL}/api/v3/report/eqcs/final-report/${id}?access_token=Bearer ${token}&tenantId=${tenantId}`;
    const detailedUrl = `${process.env.REACT_APP_REPORT_SERVER_URL}/api/v3/report/eqcs/detail-report/${id}?access_token=Bearer ${token}&tenantId=${tenantId}`;

    const handleMenuClick = (e, buttonType) => {
      // eslint-disable-next-line no-undef
      window.open(
        `${buttonType === 'DETAILED' ? detailedUrl : url}&type=${e?.key}`,
        '_blank',
      );
    };

    const items = [
      {
        key: REPORT_TYPE.PRINTABLE,
        label: <div>Print Version</div>,
      },
      {
        key: REPORT_TYPE.SHAREABLE,
        label: <div>Digital Version</div>,
      },
    ];

    const menuProps = ({ buttonType }) => {
      return {
        items,
        onClick: (e) => handleMenuClick(e, buttonType),
      };
    };

    return (
      <Row
        gutter={isDesktopViewport ? [12, 12] : [8, 8]}
        className={!isDesktopViewport ? 'mb-15' : ''}
      >
        <Col span={!isDesktopViewport && 12}>
          <Dropdown
            getPopupContainer={() =>
              // eslint-disable-next-line no-undef
              document.querySelector('.eqc-tab-details-summary')
            }
            menu={menuProps({ buttonType: 'DETAILED' })}
            trigger={['click']}
            placement="bottomCenter"
          >
            <Button
              shape="round"
              icon={<Import width="20px" />}
              className={`report-button ${
                isDesktopViewport
                  ? ''
                  : 'width-percent-100 d-flex justify-center'
              }`}
              onClick={() =>
                Event(GA_EVENT.DOWNLOAD_PROJECT_EQC_DETAILED_REPORT, {
                  label: GA_LABEL.DOWNLOAD_PROJECT_EQC_DETAILED_REPORT,
                  // eslint-disable-next-line no-undef
                  pathname: window?.location?.href,
                  project_id: projectId,
                  project_eqc_id: id,
                  user_id: currentUser?.id,
                  user_name: currentUser?.name,
                  tenant_id: currentUser?.tenantUser?.tenant?.id,
                  tenant_name:
                    currentUser?.tenantUser?.tenant?.organizationName,
                })
              }
            >
              Detailed Report
            </Button>
          </Dropdown>
        </Col>
        <Col span={!isDesktopViewport && 12}>
          <Dropdown
            getPopupContainer={() =>
              // eslint-disable-next-line no-undef
              document.querySelector('.eqc-tab-details-summary')
            }
            menu={menuProps({ buttonType: 'FINAL' })}
            trigger={['click']}
            placement="bottomCenter"
            disabled={status !== EQC_STATUS_KEYS.PASSED}
          >
            <Button
              shape="round"
              disabled={status !== EQC_STATUS_KEYS.PASSED}
              icon={<Import width="20px" />}
              className={`report-button ${
                isDesktopViewport
                  ? ''
                  : 'width-percent-100 d-flex justify-center'
              }`}
              onClick={() =>
                Event(GA_EVENT.DOWNLOAD_PROJECT_EQC_FINAL_REPORT, {
                  label: GA_LABEL.DOWNLOAD_PROJECT_EQC_FINAL_REPORT,
                  // eslint-disable-next-line no-undef
                  pathname: window?.location?.href,
                  project_id: projectId,
                  project_eqc_id: id,
                  user_id: currentUser?.id,
                  user_name: currentUser?.name,
                  tenant_id: currentUser?.tenantUser?.tenant?.id,
                  tenant_name:
                    currentUser?.tenantUser?.tenant?.organizationName,
                })
              }
            >
              Final Report
            </Button>
          </Dropdown>
        </Col>
      </Row>
    );
  };
  const handleRenameModal = () => {
    setShowRenameModal(true);
    Event(GA_EVENT.VIEW_PROJECT_EQC_RENAME_MODAL, {
      label: GA_LABEL.VIEW_PROJECT_EQC_RENAME_MODAL,
      // eslint-disable-next-line no-undef
      pathname: window?.location?.href,
      project_id: projectId,
      project_eqc_id: id,
      user_id: currentUser?.id,
      user_name: currentUser?.name,
      tenant_id: currentUser?.tenantUser?.tenant?.id,
      tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
    });
  };
  return (
    <div>
      <CommonCard className="eqc-tab-details-summary ">
        <div className="d-flex align-center justify-between mb-15">
          <div className="d-flex">
            <h2>EQC Summary</h2>
          </div>
          {isDesktopViewport && <ReportButtons />}
        </div>
        <div className="d-flex align-center justify-between mb-20 fw-medium">
          <Row gutter={isDesktopViewport ? [55, 40] : [40, 40]}>
            <Col span={!isDesktopViewport && 12}>
              <Space direction="vertical" size={12}>
                <Space size={10}>
                  <div className="d-flex align-center">
                    <div className="text-secondary mr-5">Name</div>
                    {eqcRenameLogs?.length > 0 && (
                      <InfoIcon
                        className="pointer"
                        onClick={handleRenameModal}
                      />
                    )}
                  </div>
                </Space>
                <div className="text-break eqc-text">
                  <EllipsisText text={eqcName} />
                </div>
              </Space>
            </Col>
            <Col span={!isDesktopViewport && 12}>
              <Space direction="vertical" size={12}>
                <div className="text-secondary">Checklist</div>
                <div className="text-break eqc-text">
                  <EllipsisText text={projectEqcTypeName} />
                </div>
              </Space>
            </Col>
            <Col span={!isDesktopViewport && 12}>
              <Space direction="vertical" size={12}>
                <div className="text-secondary">UOM</div>
                <div>{UOMS[uom]}</div>
              </Space>
            </Col>
            <Col span={!isDesktopViewport && 12}>
              <Space direction="vertical" size={12}>
                <div className="text-secondary">EQC Status</div>
                <div className="fw-normal">
                  <Tag className={EQC_STATUS_CLASSNAME[status]}>
                    {EQC_STATUS_LABEL[status]}
                  </Tag>
                </div>
              </Space>
            </Col>
            <Col span={!isDesktopViewport && 12}>
              <Space direction="vertical" size={12}>
                <div className="text-secondary fw-medium">Time taken</div>
                <div>
                  {eqcCompletedAt && eqcStartedAt
                    ? timeTaken(eqcStartedAt, eqcCompletedAt)
                    : '-'}
                </div>
              </Space>
            </Col>
          </Row>
          {isDesktopViewport && <CommentButtons />}
        </div>
        {!isDesktopViewport && (
          <>
            <ReportButtons /> <CommentButtons />
          </>
        )}
      </CommonCard>
      <EqcRenameModal
        showModal={showRenameModal}
        setShowModal={setShowRenameModal}
        data={eqcRenameLogs}
        projectData={projectData}
      />
      <AuditorCommentModal
        setShowModal={setShowAuditorCommentsModal}
        showModal={showAuditorCommentsModal}
        sectionId={id}
        section="EQC"
        timeZone={projectDetails?.timeZone}
      />
      <AddCommentModal
        setShowModal={setShowAddCommentsModal}
        showModal={showAddCommentsModal}
        sectionId={id}
        section="EQC"
        refetch={refetch}
      />
    </div>
  );
};

export default EqcSummary;
