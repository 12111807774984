import { useMutation } from '@apollo/client';
import { Button, message, Modal } from 'antd';
import React from 'react';
import { DELETE_REGISTER_LOG_DETAIL_ENTRY } from '../../../../graphql/Mutation';

const DeleteFormFieldRecordModal = ({
  showModal,
  setShowModal,
  selectedRecordToDelete,
  setSelectedRecordToDelete,
  handleRefetch,
}) => {
  const [deleteRegisterLogFormFieldEntry] = useMutation(
    DELETE_REGISTER_LOG_DETAIL_ENTRY,
    {
      fetchPolicy: 'network-only',
      onError() {},
      onCompleted() {
        handleRefetch();
        setShowModal(false);
      },
    },
  );

  const handleOk = async () => {
    if (selectedRecordToDelete?.key === 'new') {
      message.destroy();
      message.error('You cannot delete a new record');
      setShowModal(false);
      return;
    }
    await deleteRegisterLogFormFieldEntry({
      variables: {
        deleteRegisterLogFormFieldEntryId: selectedRecordToDelete?.id,
      },
    });
  };

  const handleCancel = () => {
    setSelectedRecordToDelete();
    setShowModal(false);
  };

  return (
    <div>
      <Modal
        maskClosable={false}
        centered
        closable={false}
        footer={null}
        open={showModal}
      >
        <h2>Delete Record</h2>
        <p>Are you sure you want to delete this record?</p>
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            No, Cancel
          </Button>
          <Button
            shape="round"
            type="primary"
            className="save-button"
            onClick={handleOk}
          >
            Yes, Delete
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteFormFieldRecordModal;
