import { Button, Form, Input, Modal, Radio, Upload } from 'antd';
import React, { useState } from 'react';
import {
  CSV_SAMPLE_FILES,
  PICK_REGISTER_LABEL,
  ROUTES,
  TAB_KEYS,
} from '../../../../../../common/constants';
import useRouter from '../../../../../../common/useRouter';
import { formValidatorRules } from '../../../../../../common/utils';

const AddRegisterModal = ({ showModal, setShowModal }) => {
  const [form] = Form.useForm();
  const {
    navigate,
    params: { projectId },
  } = useRouter();
  const { required } = formValidatorRules;
  const [pickFrom, setPickFrom] = useState(PICK_REGISTER_LABEL.CREATE);

  const renderRegisterTypeUi = (type) => {
    switch (type) {
      case PICK_REGISTER_LABEL.CREATE:
        return (
          <>
            <Form.Item
              rules={[
                required,
                {
                  max: 250,
                  message: 'Name cannot be more than 250 characters',
                },
              ]}
              name="name"
              label="Name"
            >
              <Input placeholder="Enter Name" />
            </Form.Item>
          </>
        );

      // Commented for future use
      // case PICK_REGISTER_LABEL.ORGANIZATION:
      //   return (
      //     <>
      //       <Form.Item rules={[required]} name="register" label="Register">
      //         <Input placeholder="Select Register" />
      //       </Form.Item>
      //     </>
      //   );

      case PICK_REGISTER_LABEL.IMPORT:
        return (
          <>
            <div className="mb-20">
              <Upload maxCount={1}>
                <Button shape="round" type="primary" disabled={false}>
                  Select File
                </Button>
              </Upload>
            </div>
            <h4>
              Use the
              <a
                className="sample-file pointer ml-5 mr-5"
                href={CSV_SAMPLE_FILES.REGISTER_CSV}
              >
                Sample file
              </a>
              to fill the data. Upload the file after verifying the format of
              your file with the sample file.
            </h4>
          </>
        );

      default:
        break;
    }
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const onFormSubmit = () => {
    navigate(`${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.REGISTER}/12345`, {
      state: {
        projectId,
      },
    });
  };

  return (
    <div>
      <Modal
        open={showModal}
        maskClosable={false}
        centered
        form={form}
        closable={false}
        className="import-modal"
        footer={null}
        onCancel={handleCancel}
      >
        <h2>Add Register</h2>
        <div className="mb-5">Pick From :</div>
        <div className="mb-20">
          <Radio.Group
            defaultValue={PICK_REGISTER_LABEL.CREATE}
            value={pickFrom}
            onChange={(e) => setPickFrom(e?.target?.value)}
            size="large"
          >
            {/* Commented for future use */}
            {/* <Radio value={PICK_REGISTER_LABEL.ORGANIZATION}>Organization</Radio> */}
            <Radio value={PICK_REGISTER_LABEL.CREATE}>Create</Radio>
            <Radio value={PICK_REGISTER_LABEL.IMPORT}>Import</Radio>
          </Radio.Group>
        </div>
        <Form layout="vertical" form={form} onFinish={onFormSubmit}>
          {renderRegisterTypeUi(pickFrom)}
        </Form>
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            shape="round"
            className="save-button"
            type="primary"
            htmlType="submit"
            onClick={onFormSubmit}
          >
            Save
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default AddRegisterModal;
