import { useLazyQuery } from '@apollo/client';
import { Button, Card, Col, Divider, message, Row, Tag } from 'antd';
import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import { AppContext } from '../../../../AppContext';
import { ForwardArrow } from '../../../../assets/svg';
import {
  BREAKPOINTS,
  INS_STATUS_CLASSNAME,
  INS_STATUS_KEYS,
  INS_STATUS_LABEL,
  ROUTES,
} from '../../../../common/constants';
import useRouter from '../../../../common/useRouter';
import LoaderComponent from '../../../../components/LoaderComponent';
import history from '../../../../historyData';
import { GET_DETAILS } from '../graphql/Queries';
import AddResponseDrawer from './AddResponseDrawer';
import AlreadyRespondedModal from './AlreadyRespondedModal';
import InstructionMoreDetails from './InstructionMoreDetails';
import InstructionSummary from './InstructionSummary';
import IssueActivity from './IssueActivity';
import RecommendationDetails from './RecommendationDetails';
import ShowDetailsDrawer from './ShowDetailsDrawer';

const Details = ({ token }) => {
  const { navigate, location } = useRouter();
  const navFilter = location?.state?.navFilter;
  const navPagination = location?.state?.navPagination;
  const tenantRecord = location?.state?.tenantRecord;
  const projectRecord = location?.state?.projectRecord;
  const currentPath = location?.state?.currentPath;
  const [showRespondedModal, setShowRespondedModal] = useState(false);
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [loading, setLoading] = useState(true);
  const { dispatch } = useContext(AppContext);

  useEffect(() => {
    const unlisten = history.listen((props) => {
      if (props.action === 'POP') {
        navigate(currentPath, {
          state: {
            navFilter,
            navPagination,
            tenantRecord,
            projectRecord,
          },
        });
      }
    });
    return () => {
      if (unlisten) {
        unlisten();
      }
    };
  }, []);

  const [fetchDetails, { data }] = useLazyQuery(GET_DETAILS, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      dispatch({
        type: 'SET_INSTRUCTION_DETAIL',
        data: JSON.stringify(res?.getInstructionForUser),
      });
      setLoading(false);
    },
    onError() {
      navigate(ROUTES.MAIN);
      setTimeout(() => {
        message.destroy();
        message.error('Instruction not found!');
      }, 1000);
    },
  });
  const { id } = useParams();
  useEffect(() => {
    fetchDetails({ variables: { id, token } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const instructionData = data?.getInstructionForUser;

  const refetch = () => {
    fetchDetails({ variables: { id, token } });
  };

  const status = instructionData?.status;
  useEffect(() => {
    if (status === INS_STATUS_KEYS.RESPONDED) {
      setShowRespondedModal(true);
    }
  }, [status]);
  if (loading) {
    return <LoaderComponent />;
  }
  return (
    <>
      <div
        className={clsx(
          'project-details',
          token && isDesktopViewport && 'p-20',
        )}
      >
        <div className="project-details-title justify-between page-header">
          <div className="project-details-title-text d-flex align-center">
            <h1
              className="pointer mr-10"
              onClick={() => {
                navigate(token ? ROUTES.LOGIN : `${ROUTES.TODO}/issue`, {
                  state: {
                    navFilter,
                    navPagination,
                    tenantRecord,
                    projectRecord,
                  },
                });
              }}
            >
              Issues
            </h1>
            <ForwardArrow />
            <div className="h1-typography issue-heading ellipsis-text">
              {instructionData?.name}
            </div>
            <Tag
              className={`${
                INS_STATUS_CLASSNAME[instructionData?.status]
              } ml-10 status-tag`}
            >
              {INS_STATUS_LABEL[instructionData?.status]}
            </Tag>
          </div>
        </div>
        <div className="mobile-card-wrapper">
          <Card className="issue-detail-card" bordered={false}>
            <Row
              className={clsx(
                'issue-detail-layout',
                token && 'issue-detail-public-layout',
              )}
              gutter={24}
            >
              <Col className="fixed-height" span={isDesktopViewport ? 8 : 24}>
                <Card className="instruction-tab-details-summary">
                  <InstructionMoreDetails instructionData={instructionData} />
                  {!isDesktopViewport && (
                    <>
                      <Button
                        type="link"
                        className="view-details"
                        onClick={() => setShowDetails(true)}
                      >
                        View Details
                      </Button>
                      {!isDesktopViewport &&
                        instructionData?.status === INS_STATUS_KEYS?.RAISED && (
                          <div className="add-response-button">
                            <Button
                              className="width-percent-100"
                              shape="round"
                              type="primary"
                              onClick={() => setShowResponseModal(true)}
                            >
                              Add Response
                            </Button>
                          </div>
                        )}
                    </>
                  )}
                  {isDesktopViewport && (
                    <>
                      <Divider />
                      <InstructionSummary instructionData={instructionData} />
                    </>
                  )}
                  {instructionData?.instructionHistory?.length > 0 && (
                    <>
                      <Divider />
                      <RecommendationDetails
                        data={instructionData?.instructionHistory}
                      />
                    </>
                  )}
                </Card>
                {!isDesktopViewport && (
                  <Card
                    bordered={false}
                    className={clsx(
                      'issue-activity-mobile',
                      instructionData?.status === INS_STATUS_KEYS?.RAISED &&
                        'raised-issues',
                    )}
                  >
                    <IssueActivity
                      instructionData={instructionData}
                      token={token}
                      refetch={refetch}
                    />
                  </Card>
                )}
              </Col>
              {isDesktopViewport && (
                <Col
                  className="fixed-height"
                  span={isDesktopViewport ? 16 : 24}
                >
                  <Card className="height-percent-100 overflow-auto activity-card">
                    <IssueActivity
                      instructionData={instructionData}
                      token={token}
                      refetch={refetch}
                    />
                  </Card>
                </Col>
              )}
            </Row>
          </Card>
        </div>
      </div>
      {showDetails && (
        <ShowDetailsDrawer
          instructionData={instructionData}
          open={showDetails}
          onClose={() => setShowDetails(false)}
        />
      )}
      {showResponseModal && (
        <AddResponseDrawer
          open={showResponseModal}
          instructionData={instructionData}
          onClose={() => setShowResponseModal(false)}
          token={token}
          refetch={refetch}
        />
      )}
      {showRespondedModal && (
        <AlreadyRespondedModal
          showModal={showRespondedModal}
          setShowModal={setShowRespondedModal}
          data={{
            respondedOn: instructionData?.respondedOn,
            responder: instructionData?.responderUser,
            instructionData,
          }}
        />
      )}
    </>
  );
};

export default Details;
