//! many component are commented in this file is for future use
import { PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, DatePicker, Empty, Form, Input, Spin } from 'antd';
import dayjs from 'dayjs';
import { camelCase, map } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  DeleteButton,
  DragIcon,
  EditButton,
} from '../../../../../../assets/svg';
import {
  DEFAULTDATEFORMAT,
  FIELD_TYPES,
  ROUTES,
  TAB_KEYS,
} from '../../../../../../common/constants';
import useRouter from '../../../../../../common/useRouter';
import { formValidatorRules } from '../../../../../../common/utils';
import CommonSelect from '../../../../../../components/CommonSelect';
import CommonTable from '../../../../../../components/CommonTable';
import CommonTooltip from '../../../../../../components/CommonTooltip';
import {
  CREATE_REGISTER_TABLE_FIELDS,
  UPDATE_REGISTER_FORM_FIELD_SEQUENCES,
  UPDATE_REGISTER_LOG_DETAIL_ENTRY,
} from '../../../../graphql/Mutation';
import {
  GET_REGISTER,
  GET_REGISTER_LOG_FORM_FIELDS_ENTRY_LIST,
} from '../../../../graphql/Queries';
import AddFieldModal from './AddFieldModal';
import DeleteFormFieldRecordModal from './DeleteFormFieldRecordModal';
import DeleteTableFieldModal from './registerDetails/DeleteTableFieldModal';

const ColumnContext = React.createContext({});
const Columns = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props?.column?.id || props?.dataIndex,
  });

  const style = {
    ...props?.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging
      ? {
          position: 'relative',
          zIndex: 9999,
        }
      : {}),
  };

  const contextValue = useMemo(
    () => ({
      setActivatorNodeRef,
      listeners,
    }),
    [setActivatorNodeRef, listeners],
  );

  return (
    <ColumnContext.Provider value={contextValue}>
      <th {...props} ref={setNodeRef} style={style} {...attributes} />
    </ColumnContext.Provider>
  );
};

const DragHandle = () => {
  const { setActivatorNodeRef, listeners } = useContext(ColumnContext);
  return (
    <CommonTooltip title="Change order">
      <DragIcon
        style={{
          cursor: 'move',
        }}
        ref={setActivatorNodeRef}
        {...listeners}
      />
    </CommonTooltip>
  );
};

const AddRegisterFieldsTable = ({
  createRegisterData,
  tableHeaders,
  setTableHeaders,
  tableColumns,
  setTableColumns,
  isEditable,
}) => {
  const {
    location,
    params: { projectId, registerId, registerLogId },
  } = useRouter();
  const initialFilter = {
    skip: 0,
    limit: 20,
  };
  const { number, characterWithoutWhiteSpace } = formValidatorRules;
  const [form] = Form.useForm();
  const [numericFields, setNumericFields] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [fieldsData, setFieldsData] = useState([]);
  const [selectedField, setSelectedField] = useState(false);
  const [showDeleteRecordModal, setShowDeleteRecordModal] = useState(false);
  const [selectedRecordToDelete, setSelectedRecordToDelete] = useState();
  // const [fileList, setFileList] = useState([]);
  // const [showImageSizeError, setShowImageSizeError] = useState(false);
  const tableFieldsFilter = initialFilter;

  const [getRegisterLogFormFieldsEntryList] = useLazyQuery(
    GET_REGISTER_LOG_FORM_FIELDS_ENTRY_LIST,
    {
      fetchPolicy: 'network-only',
      onError() {},
      onCompleted(response) {
        const tableData =
          response?.registerLogFormFieldEntryList?.data?.map((item) => {
            const transformedItem = {
              id: item.id || '',
              ...Object.entries(item?.responses || {}).reduce(
                (acc, [key, value]) => {
                  const field = tableColumns.find(
                    (col) => col.dataIndex === camelCase(key),
                  );
                  if (field?.type === FIELD_TYPES.DATE) {
                    if (value) {
                      const dateValue = dayjs(value);
                      acc[camelCase(key)] = dateValue.isValid()
                        ? dateValue
                        : null;
                    } else {
                      acc[camelCase(key)] = null;
                    }
                  } else {
                    acc[camelCase(key)] = value;
                  }
                  return acc;
                },
                {},
              ),
            };
            return transformedItem;
          }) || [];

        const newRow = tableHeaders?.reduce(
          (acc, field) => {
            acc[field?.dataIndex] = null;
            return acc;
          },
          { key: 'new', isEmptyRow: true },
        );

        const reversedData = [...tableData].reverse();
        reversedData.push(newRow);

        const formValues = {};
        // reversedData.forEach((row, rowIndex) => {
        //   tableHeaders.forEach((column) => {
        //     const type = column?.type;
        //     const fieldName = `${rowIndex}_${column.dataIndex}`;

        //     // if (type === FIELD_TYPES.ATTACHMENT) {
        //     //   formValues[fieldName] = row[column.dataIndex]?.split(',');
        //     //   const defaultList = (imageArr) => {
        //     //     return map(imageArr, (image, index) => ({
        //     //       uid: index,
        //     //       url: image,
        //     //     }));
        //     //   };

        //     //   // if (row[column.dataIndex]?.split(',')?.length > 0) {
        //     //   //   setFileList(defaultList(row[column.dataIndex]?.split(',')));
        //     //   // }
        //     // } else {
        //     //   formValues[fieldName] = row[column.dataIndex];
        //     // }
        //   });
        // });

        form.setFieldsValue(formValues);
        setFieldsData(reversedData);

        const columns =
          response?.registerLogFormFieldEntryList?.registerFormFields;
        const filteredColumns = map(columns, (column) => ({
          ...column,
          dataIndex: camelCase(column?.name),
          index: camelCase(column?.name),
        }));
        setTableHeaders(filteredColumns);
      },
    },
  );

  const [getRegister] = useLazyQuery(GET_REGISTER, {
    fetchPolicy: 'network-only',
    onError() {},
    onCompleted(response) {
      const data = response?.getRegister?.registerFormFields;
      const filteredColumns = map(data, (column) => ({
        ...column,
        dataIndex: camelCase(column?.name),
        index: camelCase(column?.name),
      }));
      setTableHeaders(filteredColumns);
    },
  });

  const [createRegisterLogFormFieldEntry] = useMutation(
    CREATE_REGISTER_TABLE_FIELDS,
    {
      fetchPolicy: 'network-only',
      onError() {},
      onCompleted() {
        getRegisterLogFormFieldsEntryList({
          variables: { registerLogId, filter: tableFieldsFilter },
        });
      },
    },
  );

  const handleRegisterRefetch = () => {
    getRegister({ variables: { getRegisterId: registerId } });
  };

  const [updateRegisterFormFieldSequences, { loading: updateLoading }] =
    useMutation(UPDATE_REGISTER_FORM_FIELD_SEQUENCES, {
      onError() {},
      onCompleted: () => {
        if (
          location?.pathname !==
          `${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.REGISTER}/${registerId}/edit`
        ) {
          getRegisterLogFormFieldsEntryList({
            variables: { registerLogId, filter: tableFieldsFilter },
          });
        }
        handleRegisterRefetch();
      },
    });

  const handleRefetch = () => {
    getRegisterLogFormFieldsEntryList({
      variables: { registerLogId, filter: tableFieldsFilter },
    });
  };

  const [updateRegisterLogFormFieldEntry] = useMutation(
    UPDATE_REGISTER_LOG_DETAIL_ENTRY,
    {
      fetchPolicy: 'network-only',
      onError() {},
      onCompleted() {
        handleRefetch();
      },
    },
  );

  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);
  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);

  useEffect(() => {
    if (
      !createRegisterData &&
      location?.pathname !==
        `${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.REGISTER}/${registerId}/edit`
    ) {
      getRegisterLogFormFieldsEntryList({
        variables: { registerLogId, filter: tableFieldsFilter },
      });
    }
  }, [registerLogId]);

  const handleFieldChange = async (changedFields) => {
    // Commented for future use
    // const optionsValue = tableHeaders
    //   ?.filter((item) => item?.type === FIELD_TYPES.CALCULATION)
    //   ?.map((item) => item?.options[0])
    //   ?.map((str) => str?.replace(/"/g, ''))?.[0]
    //   // ?.split(/(\+|\*|\/)/)
    //   ?.split(/[*+/()]/)
    //   ?.map((item) => item?.trim());
    // const expression = tableHeaders
    //   ?.filter((item) => item?.type === FIELD_TYPES.CALCULATION)
    //   ?.map((item) => item?.options[0])
    //   ?.map((str) => str?.replace(/"/g, ''))?.[0];
    // const operators = expression?.match(/[*+/()]/g) || [];
    // const numericFieldName = tableHeaders?.filter(
    //   (item) => item?.type === FIELD_TYPES.CALCULATION,
    // );
    // const indexData = filter(tableHeaders, (item) =>
    //   includes(optionsValue, item?.id),
    // )?.map((item) => item?.dataIndex);
    // form.setFieldsValue({
    //   [`${index}_${numericFieldName?.[0]?.dataIndex}`]:
    //     Number(values?.[`${index}_${indexData?.[0]}`]) +
    //     Number(values?.[`${index}_${indexData?.[1]}`]),
    // });

    const updatedData = [...fieldsData];
    const [fieldName, value] = Object.entries(changedFields)[0];
    const [rowIndex, columnName] = fieldName.split('_');
    const rowIndexNum = parseInt(rowIndex, 10);
    if (updatedData[rowIndexNum]) {
      updatedData[rowIndexNum] = {
        ...updatedData[rowIndexNum],
        [columnName]: value,
      };

      const isLastRow = rowIndexNum === updatedData.length - 1;
      if (isLastRow && value !== null && value !== '') {
        const newRow = tableHeaders?.reduce(
          (acc, field) => {
            acc[field?.dataIndex] = null;
            return acc;
          },
          { key: `new-${Date.now()}`, isEmptyRow: true },
        );
        updatedData.push(newRow);
      }
      setFieldsData(updatedData);
      form.setFieldsValue({
        [fieldName]: value,
      });
    }
  };

  const handleBlurSave = async (rowIndex, record, dataIndex) => {
    const allValues = form.getFieldsValue();
    const currentFieldName = `${rowIndex}_${dataIndex}`;
    const currentFieldValue = allValues[currentFieldName];

    const currentColumn = tableColumns?.find(
      (col) => col?.dataIndex === dataIndex,
    );

    if (currentColumn?.type === FIELD_TYPES.NUMERIC) {
      const numericValue = Number(currentFieldValue);
      if (Number.isNaN(numericValue) || currentFieldValue === '') {
        form.setFields([
          {
            name: currentFieldName,
            errors: ['Please enter a valid number'],
          },
        ]);
        return;
      }
    }

    const requiredColumns = tableColumns?.filter((col) => col?.isRequired);
    const hasEmptyRequiredFields = requiredColumns.some((column) => {
      const fieldName = `${rowIndex}_${column?.dataIndex}`;
      const fieldValue = allValues[fieldName];
      const isEmpty =
        !fieldValue ||
        (Array.isArray(fieldValue) && fieldValue?.length === 0) ||
        fieldValue === '';

      if (isEmpty) {
        form.setFields([
          {
            name: fieldName,
            errors: ['This field is required'],
          },
        ]);
        return true;
      }
      return false;
    });

    if (hasEmptyRequiredFields) {
      return;
    }

    const responses = [];
    let hasValidationError = false;

    tableColumns.forEach((column) => {
      const fieldName = `${rowIndex}_${column?.dataIndex}`;
      let fieldValue = allValues[fieldName];

      if (column?.type === FIELD_TYPES.CHECKBOXES) {
        if (fieldValue) {
          fieldValue = fieldValue?.join(', ');
        }
      }

      if (column?.type === FIELD_TYPES.DATE) {
        if (fieldValue) {
          const dateValue = dayjs?.isDayjs(fieldValue)
            ? fieldValue
            : dayjs(fieldValue);

          if (dateValue && dateValue?.isValid()) {
            fieldValue = dateValue?.format(DEFAULTDATEFORMAT);
          } else {
            hasValidationError = true;
            form.setFields([
              {
                name: fieldName,
                errors: ['Please enter a valid date'],
              },
            ]);
            return;
          }
        } else if (column?.isRequired) {
          hasValidationError = true;
          form.setFields([
            {
              name: fieldName,
              errors: ['This field is required'],
            },
          ]);
          return;
        }
      }

      if (column?.type === FIELD_TYPES.ATTACHMENT) {
        fieldValue = fieldValue?.join(',');
      }

      if (
        fieldValue !== undefined &&
        fieldValue !== null &&
        fieldValue !== ''
      ) {
        responses.push({
          registerFormFieldId: column?.id,
          value: Array.isArray(fieldValue) ? fieldValue : String(fieldValue),
        });
      }
    });

    if (hasValidationError) {
      return;
    }

    if (responses.length > 0) {
      try {
        if (record?.id) {
          await updateRegisterLogFormFieldEntry({
            variables: {
              updateRegisterLogFormFieldEntryId: record?.id,
              data: {
                isNewRow: false,
                responses,
              },
            },
          });
          handleRefetch();
        } else if (record?.isEmptyRow) {
          await createRegisterLogFormFieldEntry({
            variables: {
              registerLogId,
              data: {
                filling: null,
                tableEntries: responses,
              },
            },
          });
        }
      } catch (error) {
        return error;
      }
    }
  };

  const handleDeleteRecord = (record) => {
    setSelectedRecordToDelete(record);
    setShowDeleteRecordModal(true);
  };

  useEffect(() => {
    if (tableColumns?.length > 0) {
      const filteredColumns = map(tableColumns, (column) => ({
        ...column,
        dataIndex: camelCase(column?.name),
        index: camelCase(column?.name),
      }));
      setTableHeaders(filteredColumns);
    }
  }, [tableColumns]);

  useEffect(() => {
    const filteredFields = tableHeaders?.filter(
      (field) => field?.type === FIELD_TYPES.NUMERIC,
    );
    setNumericFields(filteredFields);
  }, [tableHeaders]);

  // const [fetchSignedUrl] = useLazyQuery(GET_URLS, {
  //   fetchPolicy: 'network-only',
  //   onCompleted: async (response) => {
  //     const promises = map(
  //       response?.getSignedPutUrls?.signedUrls,
  //       async (imageUrl, index) => {
  //         await fileUpload(imageUrl, fileList?.[index]?.originFileObj).catch(
  //           () => {
  //             return message.error('Image upload failed!');
  //           },
  //         );
  //       },
  //     );
  //     await Promise.all(promises);
  //   },
  //   onError() {
  //     message.error('Error getting upload URL');
  //   },
  // });
  // const handleUploadImage = async (
  //   newFileList,
  //   fieldName,
  //   record,
  //   dataIndex,
  //   index,
  // ) => {
  //   const fileSend = (anotherFileList) => {
  //     return map(anotherFileList, (file) => {
  //       const fileType = file?.originFileObj?.type || file?.type;
  //       const fileName = file?.originFileObj?.name || file?.name;

  //       return {
  //         fileName: `register/attachments/${fileName}`,
  //         contentType: fileType,
  //       };
  //     });
  //   };

  //   const filteredFileList = filter(
  //     newFileList,
  //     (file) => file?.originFileObj?.name || file?.name,
  //   );

  //   if (filteredFileList?.length) {
  //     try {
  //       fetchSignedUrl({
  //         variables: {
  //           data: fileSend(filteredFileList),
  //         },
  //       }).then(({ data }) => {
  //         const key = `${index}_${dataIndex}`;
  //         form.setFieldsValue({
  //           [key]: data?.getSignedPutUrls?.keys,
  //         });
  //         handleBlurSave(index, record, dataIndex);
  //       });
  //     } catch (error) {
  //       return error;
  //     }
  //   }
  // };

  // const handleOnChange = (info, fieldName, record, dataIndex, index) => {
  //   const newFileList = info?.fileList?.filter((file) => {
  //     const fileType = file?.originFileObj?.type || file?.type;

  //     setShowImageSizeError(false);

  //     if (file?.size > FILE_SIZE_20MB) {
  //       setShowImageSizeError(true);
  //       return false;
  //     }

  //     if (
  //       !file?.url &&
  //       fileType &&
  //       !includes([...ALLOWED_IMAGE_TYPES, 'application/pdf'], fileType)
  //     ) {
  //       message.destroy();
  //       message.error('File type should be PNG, JPG, JPEG,PDF');
  //       return false;
  //     }

  //     return true;
  //   });

  //   handleFieldChange({ [fieldName]: newFileList });
  //   setFileList(newFileList);
  //   handleUploadImage(newFileList, fieldName, record, dataIndex, index);
  // };

  // const handlePreview = async (file) => {
  //   let previewUrl = file.url;
  //   if (previewUrl) {
  //     // eslint-disable-next-line no-undef
  //     previewUrl = window.open(previewUrl, '_blanc');
  //   }
  // };
  // const defaultList = (imageArr) => {
  //   return map(imageArr, (image, index) => ({
  //     uid: index,
  //     url: image,
  //   }));
  // };
  const renderEditableCell = (
    field,
    dataIndex,
    title,
    inputType,
    record,
    index,
    options,
  ) => {
    const fieldOptions = map(options, (option) => ({
      label: option,
      value: option,
    }));

    const fieldName = `${index}_${dataIndex}`;
    switch (inputType) {
      case FIELD_TYPES.TEXT:
        return (
          <div className="width-200">
            <Form.Item
              name={fieldName}
              style={{ margin: 0 }}
              initialValue={record[dataIndex]}
              rules={[
                {
                  required: field.isRequired && !record.isEmptyRow,
                  message: `This field is required!`,
                },
                characterWithoutWhiteSpace,
              ]}
            >
              <Input
                placeholder="Enter the text"
                allowClear
                onChange={(e) =>
                  handleFieldChange({ [fieldName]: e.target.value }, index)
                }
                onBlur={() => handleBlurSave(index, record, dataIndex)}
              />
            </Form.Item>
          </div>
        );

      case FIELD_TYPES.MULTI_LINE_TEXT:
        return (
          <div className="width-200">
            <Form.Item
              name={fieldName}
              style={{ margin: 0 }}
              initialValue={record[dataIndex]}
              rules={[
                {
                  required: field.isRequired && !record.isEmptyRow,
                  message: 'This field is required!',
                },
              ]}
            >
              <Input.TextArea
                placeholder="Enter the text"
                rows={4}
                allowClear
                onChange={(e) =>
                  handleFieldChange({ [fieldName]: e.target.value })
                }
                onBlur={() => handleBlurSave(index, record, dataIndex)}
              />
            </Form.Item>
          </div>
        );

      case FIELD_TYPES.NUMERIC:
        return (
          <div className="width-200">
            <Form.Item
              name={fieldName}
              style={{ margin: 0 }}
              initialValue={record[dataIndex]}
              rules={[
                {
                  required: field.isRequired && !record.isEmptyRow,
                  message: 'This field is required!',
                },
                number,
              ]}
            >
              <Input
                placeholder="Enter the number"
                allowClear
                onChange={(e) =>
                  handleFieldChange({ [fieldName]: e.target.value }, index)
                }
                onBlur={() => handleBlurSave(index, record, dataIndex)}
              />
            </Form.Item>
          </div>
        );

      case FIELD_TYPES.DATE:
        return (
          <div className="width-200">
            <Form.Item
              name={fieldName}
              style={{ margin: 0 }}
              initialValue={record[dataIndex]}
              rules={[
                {
                  required: field.isRequired && !record.isEmptyRow,
                  message: 'This field is required!',
                },
              ]}
            >
              <DatePicker
                className="width-percent-100"
                allowClear
                onChange={(date) => handleFieldChange({ [fieldName]: date })}
                onBlur={() => handleBlurSave(index, record, dataIndex)}
                format={DEFAULTDATEFORMAT}
              />
            </Form.Item>
          </div>
        );

      case FIELD_TYPES.OPTIONS:
        return (
          <div className="width-200">
            <Form.Item
              name={fieldName}
              style={{ margin: 0 }}
              initialValue={record[dataIndex]}
              rules={[
                {
                  required: field.isRequired && !record.isEmptyRow,
                  message: 'This field is required!',
                },
                {
                  message: 'Please select an option!',
                },
              ]}
            >
              <CommonSelect
                placeholder="Select an option"
                options={fieldOptions}
                allowClear
                onChange={(value) => handleFieldChange({ [fieldName]: value })}
                onBlur={() => handleBlurSave(index, record, dataIndex)}
              />
            </Form.Item>
          </div>
        );

      case FIELD_TYPES.CHECKBOXES:
        return (
          <div className="width-200">
            <Form.Item
              name={fieldName}
              style={{ margin: 0 }}
              initialValue={record[dataIndex]}
              rules={[
                {
                  required: field.isRequired && !record.isEmptyRow,
                  message: 'This field is required!',
                },
              ]}
            >
              <CommonSelect
                placeholder="Select an option"
                mode="multiple"
                allowClear
                options={fieldOptions}
                onChange={(value) => handleFieldChange({ [fieldName]: value })}
                onBlur={() => handleBlurSave(index, record, dataIndex)}
              />
            </Form.Item>
          </div>
        );

      // case FIELD_TYPES.ATTACHMENT:
      //   return (
      //     <div className="width-200">
      //       <Form.Item
      //         name={fieldName}
      //         style={{ margin: 0 }}
      //         initialValue={record[dataIndex]}
      //         rules={[
      //           {
      //             required: field.isRequired && !record.isEmptyRow,
      //             message: 'This field is required!',
      //           },
      //         ]}
      //       >
      //         <div>
      //           <Upload
      //             maxCount={3}
      //             accept=".png,.jpg,.jpeg,.pdf"
      //             multiple
      //             listType="picture-card"
      //             fileList={
      //               // eslint-disable-next-line no-nested-ternary
      //               Array.isArray(record[dataIndex])
      //                 ? record[dataIndex]
      //                 : isString(record[dataIndex])
      //                   ? defaultList(record[dataIndex]?.split(','))
      //                   : []
      //             }
      //             onChange={(info) =>
      //               handleOnChange(info, fieldName, record, dataIndex, index)
      //             }
      //             beforeUpload={() => false}
      //             onPreview={handlePreview}
      //             showUploadList={{ showPreviewIcon: true }}
      //           >
      //             {(!Array.isArray(record[dataIndex]) ||
      //               record[dataIndex].length < 3) && (
      //               <div className="height-percent-100 width-percent-100 d-flex align-center justify-center pointer">
      //                 <Button type="link">Upload</Button>
      //               </div>
      //             )}
      //           </Upload>
      //           {showImageSizeError && (
      //             <div className="mt-10 text-danger">
      //               * Maximum file size is 20MB
      //             </div>
      //           )}
      //         </div>
      //       </Form.Item>
      //     </div>
      //   );

      case FIELD_TYPES.CALCULATION:
        return (
          <div className="width-200">
            <Form.Item
              name={fieldName}
              style={{ margin: 0 }}
              initialValue={record[dataIndex]}
            >
              <Input
                disabled
                // Commented for future use
                // value={
                //   index <= fieldsData.length - 1
                //     ? calculateFieldValue(fieldName, index, record)
                //     : ''
                // }
                onChange={(e) =>
                  handleFieldChange({ [fieldName]: e.target.value })
                }
                onBlur={() => handleBlurSave(index, record, dataIndex)}
              />
            </Form.Item>
          </div>
        );

      default:
        return null;
    }
  };

  const handleEditField = (field) => {
    setSelectedField(field);
    setShowModal(true);
  };

  const columns = useMemo(
    () => [
      ...(tableHeaders?.length > 0
        ? tableHeaders?.map((field) => ({
            ...field,
            dataIndex: field?.id,
            key: field?.id,
            id: field?.id,
            onHeaderCell: (column) => ({
              column,
              dataIndex: field?.dataIndex,
            }),
            render: (text, record, index) => {
              return !isEditable
                ? renderEditableCell(
                    field,
                    field?.dataIndex,
                    field?.title,
                    field?.type,
                    record,
                    index,
                    field?.options,
                  )
                : null;
            },
            title: (
              <div
                className="width-percent-100 align-center justify-between"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 8,
                }}
              >
                {isEditable && <DragHandle />}
                <div className="d-flex">
                  <div className="mr-5">{field?.name?.toUpperCase()}</div>
                  {field?.isRequired && <div className="danger-text">*</div>}
                </div>
                {isEditable && (
                  <div className="d-flex">
                    <EditButton
                      className="mr-10"
                      onClick={() => handleEditField(field)}
                    />
                    <DeleteButton
                      onClick={() => {
                        setSelectedField(field);
                        setShowDeleteModal(true);
                      }}
                    />
                  </div>
                )}
              </div>
            ),
            className: isEditable ? 'drag-visible' : '',
          }))
        : []),
      ...(isEditable
        ? [
            {
              key: 'addField',
              id: 'addField',
              title: (
                <Button
                  type="link"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setSelectedField();
                    setShowModal(true);
                  }}
                >
                  Add Field
                </Button>
              ),
              dataIndex: 'addField',
              render: () => null,
              onCell: () => ({
                style: { minWidth: 150 },
              }),
              disableSort: true,
              onHeaderCell: () => ({}),
              className: '',
            },
          ]
        : [
            {
              key: 'actions',
              id: 'actions',
              dataIndex: 'action',
              title: 'ACTIONS',
              render: (_, record) => (
                <div className="d-flex align-center width-percent-100">
                  <DeleteButton
                    onClick={() => handleDeleteRecord(record)}
                    style={{ cursor: 'pointer' }}
                  />
                </div>
              ),
            },
          ]),
    ],
    [tableHeaders, isEditable],
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active && over) {
      updateRegisterFormFieldSequences({
        variables: {
          data: {
            registerId,
            registerFormFieldId: active?.id,
            sequence: over?.data?.current?.sortable?.index + 1,
          },
        },
      });
    }
    if (!active || !over || active?.id === over?.id) {
      return;
    }
    const oldIndex = tableHeaders.findIndex((col) => col.id === active?.id);
    const newIndex = tableHeaders.findIndex((col) => col.id === over?.id);
    if (oldIndex !== -1 && newIndex !== -1) {
      const newHeaders = arrayMove(tableHeaders, oldIndex, newIndex);
      setTableHeaders(newHeaders);
      const newTableColumns = arrayMove(tableColumns, oldIndex, newIndex);
      setTableColumns(newTableColumns);
    }
  };

  return (
    <div>
      <DndContext
        modifiers={[restrictToHorizontalAxis]}
        onDragEnd={handleDragEnd}
        sensors={sensors}
      >
        <SortableContext
          items={tableHeaders.map((col) => col.id)}
          strategy={horizontalListSortingStrategy}
        >
          <div className="width-percent-100">
            <h3>Table</h3>
            <Spin spinning={updateLoading}>
              {!tableHeaders?.length > 0 && !isEditable ? (
                <Empty />
              ) : (
                <Form form={form} component={false}>
                  <CommonTable
                    components={{ header: { cell: Columns } }}
                    data={fieldsData}
                    columns={columns}
                    scroll={{ x: 'max-content' }}
                  />
                </Form>
              )}
            </Spin>
          </div>
        </SortableContext>
      </DndContext>
      {showModal && (
        <AddFieldModal
          createRegisterData={createRegisterData}
          tableHeaders={tableHeaders}
          setTableHeaders={setTableHeaders}
          tableColumns={tableColumns}
          setTableColumns={setTableColumns}
          isEditable={isEditable}
          selectedField={selectedField}
          isUpdate={!!selectedField}
          numericFields={numericFields}
          showModal={showModal}
          setShowModal={setShowModal}
          setSelectedField={setSelectedField}
          handleRefetch={handleRegisterRefetch}
        />
      )}
      {showDeleteModal && (
        <DeleteTableFieldModal
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          selectedField={selectedField}
          setSelectedField={setSelectedField}
          tableHeaders={tableHeaders}
          setTableHeaders={setTableHeaders}
          handleRefetch={handleRegisterRefetch}
          createRegisterData={createRegisterData}
        />
      )}
      {showDeleteRecordModal && (
        <DeleteFormFieldRecordModal
          showModal={showDeleteRecordModal}
          setShowModal={setShowDeleteRecordModal}
          selectedRecordToDelete={selectedRecordToDelete}
          setSelectedRecordToDelete={setSelectedRecordToDelete}
          handleRefetch={handleRefetch}
        />
      )}
    </div>
  );
};

export default AddRegisterFieldsTable;
