import { gql } from '@apollo/client';

export const REFRESH_TOKEN = gql`
  query refreshToken($data: RefreshTokenInput) {
    refreshToken(data: $data) {
      token
      refreshToken
    }
  }
`;

export const GET_LOGIN_TYPE = gql`
  query getLoginType($data: LoginTypeInput!) {
    getLoginType(data: $data) {
      status
      loginType
    }
  }
`;
