import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const INSTRUCTION_LOGS = gql`
  query instructionActivityLogList($filter: InstructionActivityLogFilter) {
    instructionActivityLogList(filter: $filter) {
      total
      data {
        id
        instructionId
        projectName
        projectId
        instruction {
          name
          type
          tags
        }
        dueDate
        creator
        agencyName
        createdAt
        status
        responder
        userPhoneNo
        agencyType
        timeZone
      }
    }
  }
`;

export const ACTIVITY_LOGS = gql`
  query eqcActivityLogList($filter: EqcActivityLogFilter) {
    eqcActivityLogList(filter: $filter) {
      total
      data {
        id
        projectId
        projectName
        eqcType
        eqcStageName
        eqcId
        stageId
        eqcStagesId
        eqcName
        eqcStatus
        eqcStageStatus
        creatorName
        levelName
        levelNumber
        externalUserApproverName
        internalApproverName
        timeZone
        eqcStageApprovals {
          id
          internalApproverId
          externalApproverId
          internalApproverName
          externalUserApproverName
          levelName
          levelNumber
        }
        dueDate
        createdAt
      }
    }
  }
`;

export const EQC_LIST = gql`
  query eqcTypeDropdownList($filter: EqcTypeFilter) {
    eqcTypeDropdownList(filter: $filter) {
      total
      data {
        id
        name
      }
    }
  }
`;
export const TENANT_ASSET_LIST = gql`
  query tenantAssetList($filter: AssetFilter!) {
    tenantAssetList(filter: $filter) {
      total
      data {
        id
        imageUrl
        thumbnailUrl
        eqcId
        instructionId
        assetName
        createdAt
        eqc {
          eqcName
          projectEqcTypeId
          project {
            id
            name
          }
          tenant {
            id
            organizationName
          }
        }
        instruction {
          name
          project {
            id
            name
          }
          tenant {
            id
            organizationName
          }
        }
        eqcStage {
          name
          projectEqcTypeStageId
        }
        eqcStageItem {
          title
          projectEqcTypeStageItemId
        }
      }
    }
  }
`;
