import { Button, Form, Modal } from 'antd';
import {
  fill,
  filter,
  findIndex,
  flattenDeep,
  includes,
  map,
  uniqBy,
} from 'lodash';
import React, { useEffect, useState } from 'react';
import CommonDropdown from '../../components/CommonDropdown';
import {
  GET_EXTERNAL_USERS_LIST,
  GET_INTERNAL_USERS_LIST,
} from '../projects/graphql/Queries';

const approvers = [];
const ReplaceApproverModal = ({
  showModal,
  setShowModal,
  approverData,
  replacedApproverData,
  setReplacedApproverData,
  isUpdate,
  setReplacedApprovers,
  replacedApprovers,
}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (approverData && !isLoading) {
      const internalApproverIds = [];
      const externalApproverIds = [];
      map(
        approverData?.projectEqcTypeStageApprovals,
        ({ internalApproverId, externalApproverId }) => {
          if (
            internalApproverId !== approverData?.projectUserId &&
            internalApproverId
          ) {
            internalApproverIds.push(internalApproverId);
          }
          if (externalApproverId) {
            externalApproverIds.push(externalApproverId);
          }
        },
      );
      form.setFieldsValue({
        internalApproverIds: internalApproverIds || [],
        externalApproverIds: externalApproverIds || [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approverData, isLoading]);

  useEffect(() => {
    if (isUpdate?.length > 0 && !isLoading) {
      form.setFieldsValue({
        internalApproverIds: isUpdate?.[0]?.internalApproverIds,
        externalApproverIds: isUpdate?.[0]?.externalApproverIds,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdate, isLoading]);

  const handleCancel = () => {
    setShowModal(false);
    form.resetFields();
  };

  const onSubmitFinish = async (formValues) => {
    const data = {
      id: approverData?.id,
      projectId: approverData?.projectId,
      projectEqcTypeStageId: approverData?.stageId,
      internalApproverIds: formValues?.internalApproverIds || [],
      externalApproverIds: formValues?.externalApproverIds || [],
      levelNumber: approverData?.levelNumber,
    };
    if (isUpdate?.length > 0) {
      const index = findIndex(replacedApproverData, {
        id: approverData?.id,
      });
      fill(replacedApproverData, data, index, index + 1);
    } else {
      setReplacedApproverData([...replacedApproverData, data]);
    }
    const newApproverData = filter(flattenDeep(approvers), (item) => {
      if (
        includes(
          [...data?.externalApproverIds, ...data?.internalApproverIds],
          item?.id,
        )
      ) {
        return item;
      }
    });
    setReplacedApprovers({
      ...replacedApprovers,
      [approverData?.id]: uniqBy(newApproverData, 'id'),
    });
    setShowModal(false);
  };
  const handleDropdownChange = (value, addExtraData) => {
    setIsLoading(false);
    approvers.push(
      value?.internalApproverDropdownList?.data ||
        value?.externalUserApproverDropdownList?.data,
    );
    if (addExtraData) {
      approvers.push(addExtraData);
    }
  };
  return (
    <Modal
      maskClosable={false}
      centered
      open={showModal}
      className="dialog"
      footer={null}
      closable={false}
      destroyOnClose
    >
      <h2>Replace Approver</h2>
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmitFinish}
        className="stage-config"
      >
        <Form.Item
          className="mt-10"
          name="internalApproverIds"
          dependencies={['externalApproverIds']}
          rules={[
            ({ getFieldValue }) => ({
              required: !getFieldValue('externalApproverIds')?.length,
              message: 'Please select approver',
              type: 'array',
            }),
          ]}
          label={
            <div className="d-flex justify-between width-percent-100">
              <span>Internal Approvers</span>
            </div>
          }
        >
          <CommonDropdown
            mode="multiple"
            showSearch
            allowClear
            showExtraLoader={isLoading}
            optionFilterProp="children"
            placeholder="Select Internal Approvers"
            query={GET_INTERNAL_USERS_LIST}
            variables={{
              filter: { projectId: approverData?.projectId },
            }}
            responsePath="internalApproverDropdownList.data"
            valuePath="id"
            labelPath="user.name"
            optionKey="internal-approver"
            fetchPolicy="network-only"
            callback={handleDropdownChange}
            addExtraData={filter(
              replacedApprovers?.[approverData?.id],
              // eslint-disable-next-line no-underscore-dangle
              (record) => record?.__typename === 'ProjectUser',
            )}
            filterDataById={approverData?.projectUserId}
          />
        </Form.Item>
        <Form.Item
          name="externalApproverIds"
          dependencies={['internalApproverIds']}
          rules={[
            ({ getFieldValue }) => ({
              required: !getFieldValue('internalApproverIds')?.length,
              message: 'Please select approver',
              type: 'array',
            }),
          ]}
          label={
            <div className="d-flex justify-between width-percent-100">
              <span>External Approvers</span>
            </div>
          }
        >
          <CommonDropdown
            mode="multiple"
            showSearch
            allowClear
            showExtraLoader={isLoading}
            optionFilterProp="children"
            placeholder="Select External Approvers"
            query={GET_EXTERNAL_USERS_LIST}
            variables={{
              filter: {
                projectId: approverData?.projectId,
                projectEqcTypeStageId: approverData?.stageId,
              },
            }}
            responsePath="externalUserApproverDropdownList.data"
            valuePath="id"
            labelPath={['name', 'agency.name']}
            optionKey="external-approver"
            fetchPolicy="network-only"
            callback={handleDropdownChange}
            addExtraData={filter(
              replacedApprovers?.[approverData?.id],
              // eslint-disable-next-line no-underscore-dangle
              (record) => record?.__typename === 'Contact',
            )}
          />
        </Form.Item>
        <div className="form-buttons mt-15">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            shape="round"
            type="primary"
            className="save-button"
            htmlType="submit"
          >
            Add
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ReplaceApproverModal;
