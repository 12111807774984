import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Descriptions, Divider, Space, Tag } from 'antd';
import clsx from 'clsx';
import {
  capitalize,
  filter,
  forEach,
  groupBy,
  keys,
  map,
  nth,
  slice,
} from 'lodash';
import React, { useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import {
  AddButton,
  DeleteButton,
  DuplicateIcon,
  PersonDone,
} from '../../../../../../assets/svg';
import {
  ADDONS,
  ALLOWED_ACTION_KEYS,
  ALLOWED_ACTION_TYPE,
  BREAKPOINTS,
  CHECKLIST_STATUS_CLASSNAME,
  CHECKLIST_STATUS_LABEL,
  DEFAULT_PAGE_SIZE,
  PROJECT_CHECKLIST_STATUS,
  ROUTES,
  TAB_KEYS,
} from '../../../../../../common/constants';
import useRouter from '../../../../../../common/useRouter';
import { removeHistoryStateData } from '../../../../../../common/utils';
import CanPerform from '../../../../../../components/CanPerform';
import CommonCard from '../../../../../../components/CommonCard';
import CommonPopover from '../../../../../../components/CommonPopover';
import CommonSelect from '../../../../../../components/CommonSelect';
import CommonTable from '../../../../../../components/CommonTable';
import CommonTooltip from '../../../../../../components/CommonTooltip';
import EllipsisText from '../../../../../../components/EllipsisText';
import InfiniteScrollHandler from '../../../../../../components/InfiniteScrollHandler';
import SearchComponent from '../../../../../../components/SearchComponent';
import { DELETE_DRAFT_PROJECT_EQC_TYPE } from '../../../../graphql/Mutation';
import { GET_PROJECT_EQC_TYPES_LIST } from '../../../../graphql/Queries';
import AddEqcTypeModal from './AddEqcTypeModal';
import ApproversDrawer from './ApproversDrawer';
import DeleteChecklistModal from './DeleteChecklistModal';
import DuplicateEqcTypeModal from './DuplicateEqcType';
import EditEqcTypeModal from './EditEqcTypeModal';

const EqcTypes = () => {
  const {
    location,
    navigate,
    params: { projectId },
  } = useRouter();
  const initialProjectEqcTypeFilter = {
    skip: 0,
    limit: 10,
    projectId: Number(projectId),
    sortBy: { field: 'createdAt', order: 'DESC' },
  };
  const initialPaginationValue = {
    total: 0,
    current: 1,
  };

  const navFilter = location?.state?.navFilter;
  const navPagination = location?.state?.navPagination;
  const eqcTypeFilter = location?.state?.eqcTypeFilter;
  const eqcTypePagination = location?.state?.eqcTypePagination;
  const [paginationProp, setPaginationProp] = useState(
    eqcTypePagination || initialPaginationValue,
  );
  const [projectEqcTypeFilter, setProjectEqcTypeFilter] = useState(
    eqcTypeFilter || initialProjectEqcTypeFilter,
  );
  const [showModal, setShowModal] = useState(false);
  const [eqcTypeData, setEqcTypeData] = useState();
  const [duplicateModal, setDuplicateModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState('');
  const [mutationId, setMutationId] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [hasMore, setHasMore] = useState(true);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [eqcTypeListData, setEqcTypeListData] = useState();
  const [totalEqcTypeListData, setTotalEqcTypeListData] = useState();
  const [checklistStatus, setChecklistStatus] = useState(
    eqcTypeFilter?.status?.[0],
  );
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [open, setOpen] = useState(false);
  const [drawerData, setDrawerData] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [name, setName] = useState('');
  useEffect(() => {
    if (eqcTypeFilter) {
      setProjectEqcTypeFilter(eqcTypeFilter);
    }
    if (eqcTypeFilter?.status?.[0]) {
      setChecklistStatus(eqcTypeFilter?.status?.[0]);
    }
    if (eqcTypePagination) {
      setPaginationProp(eqcTypePagination);
    }
  }, [eqcTypeFilter, eqcTypePagination]);

  const [fetchEqcType, { loading }] = useLazyQuery(GET_PROJECT_EQC_TYPES_LIST, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setTotalEqcTypeListData(res?.projectEqcTypeList?.total);
      const data = res?.projectEqcTypeList?.data;
      const pagination = {
        ...paginationProp,
        total: res?.projectEqcTypeList?.total,
      };
      if (scrollFlag) {
        const datalist = [...eqcTypeListData, ...data];
        setEqcTypeListData(datalist);
        setScrollFlag(false);
      } else {
        const datalist = [...data];
        setEqcTypeListData(datalist);
      }
      setHasMore(!!data?.length);
      setPaginationProp(pagination);
    },
    onError() {},
  });

  useEffect(() => {
    fetchEqcType({ variables: { filter: projectEqcTypeFilter } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectEqcTypeFilter]);

  useEffect(() => {
    const newFilters = {
      ...projectEqcTypeFilter,
      skip: projectEqcTypeFilter?.skip !== 0 ? projectEqcTypeFilter?.skip : 0,
      status: checklistStatus ? [checklistStatus] : undefined,
    };
    setProjectEqcTypeFilter(newFilters);
    setPaginationProp({
      ...paginationProp,
      current: paginationProp?.current !== 1 ? paginationProp?.current : 1,
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklistStatus]);

  const refetchEqcTypeDetails = () => {
    fetchEqcType({
      variables: { filter: { ...projectEqcTypeFilter } },
    });
  };

  const [deleteDraftProjectEqcType] = useMutation(
    DELETE_DRAFT_PROJECT_EQC_TYPE,
    {
      onError() {},
      onCompleted() {
        refetchEqcTypeDetails();
      },
    },
  );

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setPaginationProp({ ...paginationProp, ...pagination });
    setSortedInfo(sorter);
    if (sorter?.column) {
      setProjectEqcTypeFilter({
        ...projectEqcTypeFilter,
        skip,
        limit: pagination.pageSize,
        sortBy: {
          field: sorter.columnKey,
          order: sorter.order === 'ascend' ? 'ASC' : 'DESC',
        },
      });
    } else {
      setProjectEqcTypeFilter({
        ...projectEqcTypeFilter,
        skip,
        limit: pagination.pageSize,
        sortBy: { field: 'createdAt', order: 'DESC' },
      });
    }
  };

  const handleAddEqcType = () => {
    setShowAddModal(true);
  };

  const getProjectUsers = (record) => {
    const fetchName = [];
    if (record?.length !== 0) {
      forEach(record, (o) => {
        fetchName.push(o?.projectUser?.user?.name);
      });
    }
    const selectedName = nth(fetchName, 0);
    if (fetchName?.length === 1) {
      return (
        <CommonTooltip
          title={selectedName}
          placement={isDesktopViewport ? 'right' : 'top'}
          onClick={(e) => !isDesktopViewport && e.stopPropagation()}
        >
          {selectedName}
        </CommonTooltip>
      );
    }
    if (fetchName?.length > 1) {
      return (
        <CommonTooltip
          title={slice(fetchName, 1, fetchName?.length).join(', ')}
          placement={isDesktopViewport ? 'right' : 'top'}
          onClick={(e) => !isDesktopViewport && e.stopPropagation()}
        >
          {`${selectedName} and +${fetchName?.length - 1}`}
        </CommonTooltip>
      );
    }

    return '-';
  };
  const getProjectAgencies = (record) => {
    const fetchName = [];
    if (record?.projectAgencyEqcTypes?.length !== 0) {
      forEach(record?.projectAgencyEqcTypes, (o) => {
        fetchName.push(o?.projectAgency?.agency?.name);
      });
    }
    const selectedName = nth(fetchName, 0);
    if (fetchName?.length === 1) {
      return (
        <CommonTooltip
          title={selectedName}
          placement={isDesktopViewport ? 'right' : 'top'}
          onClick={(e) => !isDesktopViewport && e.stopPropagation()}
        >
          {selectedName}
        </CommonTooltip>
      );
    }
    if (fetchName?.length > 1) {
      return (
        <CommonTooltip
          title={slice(fetchName, 1, fetchName?.length).join(', ')}
          placement={isDesktopViewport ? 'right' : 'top'}
          onClick={(e) => !isDesktopViewport && e.stopPropagation()}
        >
          {`${selectedName} and +${fetchName?.length - 1}`}
        </CommonTooltip>
      );
    }
    return '-';
  };

  const getStatus = (value) => (
    <Tag className={CHECKLIST_STATUS_CLASSNAME[value]}>
      {capitalize(CHECKLIST_STATUS_LABEL[value])}
    </Tag>
  );
  const getLevelWiseApprovers = (record, arrayLength, index) => {
    const levelWiseApprovers = groupBy(record, (b) => {
      return b?.levelNumber;
    });
    return (
      <div className="width-percent-100">
        <Descriptions
          column={3}
          layout="vertical"
          colon={false}
          className={clsx('inner', index % 2 === 0 && 'mr-16')}
        >
          {map(Object.keys(levelWiseApprovers), (data) => {
            return (
              <Descriptions.Item
                label={`${levelWiseApprovers?.[data]?.[0]?.levelName}:`}
                span={3}
                key={data}
              >
                {map(levelWiseApprovers?.[data], (approver) => {
                  return (
                    approver?.internalApproverName ||
                    approver?.externalUserApproverName
                  );
                }).join(', ')}
              </Descriptions.Item>
            );
          })}
        </Descriptions>
        {!isDesktopViewport && index < arrayLength - 1 && <Divider />}
      </div>
    );
  };
  const getStageDetails = (record) => {
    const { projectEqcTypeStages = [] } = record;
    const StageDetails = filter(
      projectEqcTypeStages,
      'projectEqcTypeStageApprovals',
    );
    return (
      <Descriptions
        column={isDesktopViewport ? 6 : 3}
        layout="vertical"
        colon={false}
        className="outer mr-10"
      >
        {map(StageDetails, (data, index) => {
          return (
            <Descriptions.Item label={`${data?.name}:`} span={3} key={data?.id}>
              {getLevelWiseApprovers(
                data?.projectEqcTypeStageApprovals,
                StageDetails?.length,
                index,
              )}
            </Descriptions.Item>
          );
        })}
      </Descriptions>
    );
  };
  const getApprovers = (record) => {
    const { projectEqcTypeStages = [] } = record;
    const StageDetails = filter(
      projectEqcTypeStages,
      'projectEqcTypeStageApprovals',
    );
    return (
      <div>
        <div className="d-flex align-center">
          {isDesktopViewport ? (
            <CommonPopover
              overlayClassName="checklist-approvers-popup"
              getPopupContainer={() =>
                // eslint-disable-next-line no-undef
                document.querySelector('.checklists')
              }
              title="Approvers info:"
              trigger={isDesktopViewport ? 'hover' : 'click'}
              placement={isDesktopViewport ? 'left' : 'top'}
              destroyTooltipOnHide
              content={
                <div onClick={(e) => e.stopPropagation()}>
                  {getStageDetails(record)}
                </div>
              }
            >
              {StageDetails?.length > 0 && (
                <PersonDone
                  className="checklist-approvers mr-10"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                />
              )}
            </CommonPopover>
          ) : (
            <>
              {StageDetails?.length > 0 && (
                <PersonDone
                  className="checklist-approvers mr-10"
                  onClick={(e) => {
                    e.stopPropagation();
                    setDrawerData(record);
                    setOpen(true);
                  }}
                />
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  const getActionButtons = (record) => {
    const handleDuplicate = (e) => {
      e?.stopPropagation?.();
      setMutationId(record?.id);
      setDuplicateModal(true);
    };
    return (
      <CanPerform
        action={ALLOWED_ACTION_KEYS.EDIT_PROJECT_CHECKLISTS}
        type={ALLOWED_ACTION_TYPE.BOTH}
      >
        <Space size={0}>
          <>{getApprovers(record)}</>
          <CommonTooltip
            trigger="focus"
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            title="Duplicate"
          >
            <Button
              shape="round"
              icon={<DuplicateIcon />}
              onClick={handleDuplicate}
            />
          </CommonTooltip>
          {record?.status === PROJECT_CHECKLIST_STATUS.UNPUBLISHED && (
            <CommonTooltip trigger="focus" title="Delete">
              <Button
                shape="round"
                icon={<DeleteButton />}
                onClick={(e) => {
                  e.stopPropagation();
                  setName(record?.name);
                  setMutationId(record?.id);
                  setDeleteModal(true);
                }}
              />
            </CommonTooltip>
          )}
        </Space>
      </CanPerform>
    );
  };
  const columns = [
    {
      title: '#',
      key: 'id',
      render: (text, record, index) => {
        return <div>{projectEqcTypeFilter?.skip + index + 1}</div>;
      },
    },
    {
      title: 'NAME',
      key: 'name',
      dataIndex: 'name',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
      render: (text, record) => {
        return (
          <div className="d-flex align-center">
            <EllipsisText text={text || '-'} />
            {record?.isRfiActive && (
              <div>
                <Tag className="ml-5" color="#202A3F">
                  {ADDONS.RFI}
                </Tag>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: 'CHECKLIST USER',
      key: 'user',
      dataIndex: 'projectUserEqcTypes',
      render: (text, record) => getProjectUsers(record?.projectUserEqcTypes),
    },
    {
      title: 'RFI USER',
      key: 'user',
      dataIndex: 'projectRFIUsers',
      render: (text, record) => getProjectUsers(record?.projectRFIUsers),
    },
    {
      title: 'AGENCIES',
      key: 'agency',
      dataIndex: 'projectAgencyEqcTypes',
      render: (text, record) => getProjectAgencies(record),
    },
    {
      title: 'STATUS',
      key: 'status',
      dataIndex: 'status',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'status' && sortedInfo?.order,
      render: (text) => getStatus(text),
    },
    {
      key: 'actions',
      align: 'right',
      render: (record) => getActionButtons(record),
    },
  ];

  const onSearchChange = async (value) => {
    if (!value) {
      removeHistoryStateData(navigate, location, 'eqcTypeFilter', 'search');
    }
    setProjectEqcTypeFilter({
      ...projectEqcTypeFilter,
      skip: 0,
      search: value,
    });
    setPaginationProp(initialPaginationValue);
  };
  const handleRefetch = () => {
    fetchEqcType({
      variables: {
        filter: {
          ...projectEqcTypeFilter,
          skip: eqcTypeListData?.length,
          limit: DEFAULT_PAGE_SIZE,
          sortBy: { field: 'createdAt', order: 'DESC' },
        },
      },
    });
  };
  const handleRowClick = (record) => {
    if (record?.id) {
      navigate(
        `${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.CHECKLISTS}/${record?.id}`,
        {
          state: {
            navFilter,
            navPagination,
            eqcTypeFilter: projectEqcTypeFilter,
            eqcTypePagination: paginationProp,
          },
        },
      );
    }
  };

  return (
    <>
      <CanPerform
        action={ALLOWED_ACTION_KEYS.EDIT_PROJECT_CHECKLISTS}
        type={ALLOWED_ACTION_TYPE.BOTH}
      >
        <EditEqcTypeModal
          showModal={showModal}
          setShowModal={setShowModal}
          eqcTypeData={eqcTypeData}
          setEqcTypeData={setEqcTypeData}
          refetchEqcTypeData={() =>
            fetchEqcType({
              variables: { filter: projectEqcTypeFilter },
            })
          }
        />
        {duplicateModal && (
          <DuplicateEqcTypeModal
            showModal={duplicateModal}
            setShowModal={setDuplicateModal}
            projectEqcTypeId={mutationId}
            setMutationId={setMutationId}
            refetchEqcTypeData={refetchEqcTypeDetails}
          />
        )}
        {showAddModal && (
          <AddEqcTypeModal
            showModal={showAddModal}
            setShowModal={setShowAddModal}
            refetchEqcTypeData={refetchEqcTypeDetails}
          />
        )}
        {deleteModal && (
          <DeleteChecklistModal
            showModal={deleteModal}
            setShowModal={setDeleteModal}
            name={name}
            deleteDraftProjectEqcType={deleteDraftProjectEqcType}
            mutationId={mutationId}
            setMutationId={setMutationId}
            fetchProjectUser={refetchEqcTypeDetails}
          />
        )}
      </CanPerform>
      <div className="checklists">
        <div
          className={`agency-details-header mb-20 ${
            isDesktopViewport ? 'd-flex justify-between align-center' : ''
          }`}
        >
          <div className="d-flex justify-between align-center mb-10">
            <div>
              <h2>Checklists</h2>
              <div className="mt-15 data-count">
                {totalEqcTypeListData}
                {totalEqcTypeListData <= 1 ? ` Checklist` : ` Checklists`}
              </div>
            </div>
            {!isDesktopViewport && (
              <CanPerform
                action={ALLOWED_ACTION_KEYS.EDIT_PROJECT_CHECKLISTS}
                type={ALLOWED_ACTION_TYPE.BOTH}
              >
                <Button
                  shape="round"
                  icon={<AddButton />}
                  type="primary"
                  className="d-flex align-center"
                  onClick={() => {
                    handleAddEqcType();
                  }}
                >
                  Add
                </Button>
              </CanPerform>
            )}
          </div>
          <div className={clsx(isDesktopViewport && 'd-flex align-center')}>
            <CommonSelect
              placeholder="Status: All"
              className={`instruction-selector mr-10 ${
                isDesktopViewport ? 'width-200' : 'width-percent-100 mb-10'
              } `}
              value={checklistStatus}
              onChange={(status) => {
                setChecklistStatus(status);
                setProjectEqcTypeFilter({ ...projectEqcTypeFilter, skip: 0 });
                setPaginationProp({ ...paginationProp, current: 1 });
                if (!status) {
                  removeHistoryStateData(
                    navigate,
                    location,
                    'eqcTypeFilter',
                    'status',
                  );
                }
              }}
              allowClear
              options={keys(CHECKLIST_STATUS_LABEL)
                .filter((status) => status !== CHECKLIST_STATUS_LABEL.DRAFT) // Filter out "DRAFT" status
                .map((status, index) => ({
                  key: index,
                  value: status,
                  label: capitalize(CHECKLIST_STATUS_LABEL[status]),
                }))}
            />
            <SearchComponent
              className={`search-component mr-10 ${
                !isDesktopViewport ? 'width-percent-100' : ''
              }`}
              getData={onSearchChange}
              defaultValue={eqcTypeFilter?.search}
            />
            {isDesktopViewport && (
              <CanPerform
                action={ALLOWED_ACTION_KEYS.EDIT_PROJECT_CHECKLISTS}
                type={ALLOWED_ACTION_TYPE.BOTH}
              >
                <Button
                  shape="round"
                  icon={
                    <AddButton
                      width="25px"
                      className={clsx(isDesktopViewport && 'mr-5')}
                    />
                  }
                  type="primary"
                  className="d-flex align-center"
                  onClick={() => {
                    handleAddEqcType();
                  }}
                >
                  Add
                </Button>
              </CanPerform>
            )}
          </div>
        </div>
        {isDesktopViewport ? (
          <div className="table pointer">
            <CommonTable
              columns={columns}
              data={eqcTypeListData || []}
              loading={loading}
              onChange={handleTableChange}
              paginationConfig={paginationProp}
              rowKey={(item) => item?.key ?? item?.id}
              onRow={(record) => {
                return {
                  onClick: () => handleRowClick(record),
                };
              }}
            />
          </div>
        ) : (
          <InfiniteScrollHandler
            scrollFlag={scrollFlag}
            loading={loading}
            refetchData={handleRefetch}
            setScrollFlag={setScrollFlag}
            hasMore={hasMore}
            dataLength={eqcTypeListData?.length}
            skeletonRows={columns?.length - 3}
          >
            {map(eqcTypeListData, (eqcType, index) => {
              return (
                <CommonCard
                  key={eqcType?.id}
                  onClick={() => handleRowClick(eqcType)}
                >
                  <div className="common-card d-flex">
                    <div className="mr-5 fw-medium">
                      <div className="d-flex align-center">{index + 1}.</div>
                    </div>
                    <div>
                      <div className="card-header fw-medium">
                        <span className="text-break">{eqcType?.name}</span>
                      </div>
                      <div className="card-content text-secondary">
                        <br />
                        <div className="mb-15">
                          <span className="fw-medium mr-5">User:</span>
                          {getProjectUsers(eqcType?.projectUserEqcTypes)}
                        </div>
                        <div className="mb-15">
                          <span className="fw-medium mr-5">RFI User:</span>
                          {getProjectUsers(eqcType?.projectRFIUsers)}
                        </div>
                        <div className="mb-15">
                          <span className="fw-medium mr-5">Agencies:</span>
                          {getProjectAgencies(eqcType)}
                        </div>
                        <div>
                          <span className="fw-medium mr-5">Status:</span>
                          {getStatus(eqcType?.status, eqcType)}
                        </div>
                      </div>
                    </div>
                    <span
                      className="d-flex position-absolute user-action-btn"
                      onClick={(e) => e?.stopPropagation?.()}
                    >
                      {getActionButtons(eqcType)}
                    </span>
                  </div>
                </CommonCard>
              );
            })}
          </InfiniteScrollHandler>
        )}
        {open && !isDesktopViewport && (
          <ApproversDrawer open={open} setOpen={setOpen}>
            {getStageDetails(drawerData)}
          </ApproversDrawer>
        )}
      </div>
    </>
  );
};

export default EqcTypes;
