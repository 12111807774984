import { useLazyQuery } from '@apollo/client';
import { Button, Dropdown, Space } from 'antd';
import clsx from 'clsx';
import { findIndex, isNaN } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../../../../../AppContext';
import {
  Export,
  ForwardArrow,
  ProjectIcon,
} from '../../../../../../assets/svg';
import {
  BREAKPOINTS,
  GA_EVENT,
  GA_LABEL,
  ROUTES,
  TAB_KEYS,
} from '../../../../../../common/constants';
import { Event } from '../../../../../../common/trackEvents';
import useRouter from '../../../../../../common/useRouter';
import EllipsisText from '../../../../../../components/EllipsisText';
import { GET_SINGLE_PROJECT } from '../../../../graphql/Queries';
import AddCommentModal from '../eqc/eqcDetails/AddCommentModal';
import RegisterPropertyDetails from './registerDetails/RegisterPropertyDetails';

const RegisterDetailsContent = () => {
  const {
    getCurrentUser,
    dispatch,
    getToken,
    getCurrentTenant,
    getTenantUser,
  } = useContext(AppContext);
  const tenantId = getCurrentTenant() || getTenantUser()?.tenant?.id;
  const currentUser = getCurrentUser();
  const currentUserId = getCurrentUser()?.id || {};
  const {
    navigate,
    location,
    params: { projectId, registerId, registerLogId },
  } = useRouter();
  const [showAddCommentsModal, setShowAddCommentsModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const navFilter = location?.state?.navFilter;
  const navPagination = location?.state?.navPagination;
  const registerDetailsData = location?.state?.registerDetailsData;
  const currentPath = location?.state?.currentPath;
  const showListView = location?.state?.showListView;

  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const createRegisterData = location?.state?.createRegisterData;

  const [getWebProject, { data: projectData }] = useLazyQuery(
    GET_SINGLE_PROJECT,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        dispatch({
          type: 'SET_PROJECT_DETAILS',
          data: JSON.stringify(res?.getProject),
        });
        const index = findIndex(
          res?.getProject?.projectUsers,
          (user) => Number(user?.userId) === Number(currentUserId),
        );
        if (index !== -1) {
          const loggedInUserRole =
            res?.getProject?.projectUsers?.[index]?.roles;
          dispatch({ type: 'SET_PROJECT_ROLE', data: loggedInUserRole });
        } else {
          dispatch({ type: 'SET_PROJECT_ROLE', data: '' });
        }
      },
    },
  );

  const renderDownloadCsvButton = () => {
    const token = getToken();
    const url = `${process.env.REACT_APP_REPORT_SERVER_URL}/api/v1/export-csv/report/register/${showListView ? 'logs' : 'entries'}?access_token=Bearer ${token}&tenantId=${tenantId}&registerId=${registerId}&registerLogId=${registerLogId}`;
    return (
      <Button
        type="ghost"
        className="export-button ant-btn-icon-only d-flex b-0 justify-center width-percent-100"
        target="_blank"
        href={url}
        onClick={() => {
          Event(GA_EVENT.EXPORT_PROJECT_REGISTER_CSV, {
            label: GA_LABEL.EXPORT_PROJECT_REGISTER_CSV,
            // eslint-disable-next-line no-undef
            pathname: window?.location?.href,
            project_id: projectId,
            project_register_id: registerId,
            user_id: currentUser?.id,
            user_name: currentUser?.name,
            tenant_id: currentUser?.tenantUser?.tenant?.id,
            tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
          });
        }}
      >
        CSV
      </Button>
    );
  };

  useEffect(() => {
    if (!isNaN(Number(projectId))) {
      getWebProject({
        variables: {
          id: projectId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (
      location?.pathname ===
      `${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.REGISTER}/${registerId}/edit`
    ) {
      setIsUpdate(true);
    }
  }, []);

  return (
    <div className="project-details">
      <AddCommentModal
        setShowModal={setShowAddCommentsModal}
        showModal={showAddCommentsModal}
      />
      <div
        className={`project-details-title details-header justify-between page-header ${
          isDesktopViewport ? 'd-flex' : ''
        }`}
      >
        <div
          className={`project-details-title-text d-flex ${isDesktopViewport ? '' : 'mb-15'}`}
        >
          <ProjectIcon
            onClick={() => {
              navigate(ROUTES.PROJECTS, {
                state: {
                  navFilter,
                  navPagination,
                },
              });
            }}
            className="pointer"
          />
          <ForwardArrow />
          <h1
            className="pointer mr-10 text-primary"
            onClick={() => {
              navigate(`${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.REGISTER}`, {
                state: {
                  navFilter,
                  navPagination,
                  currentPath,
                },
              });
            }}
          >
            {projectData?.getProject?.name}
          </h1>
          {!createRegisterData && !isUpdate && (
            <>
              <ForwardArrow />
              <EllipsisText
                className="h1-typography"
                text={registerDetailsData?.name}
              />
            </>
          )}
          {createRegisterData && !isUpdate && (
            <>
              <ForwardArrow />
              <EllipsisText className="h1-typography" text="Create Register" />
            </>
          )}
          {isUpdate && (
            <>
              <ForwardArrow />
              <EllipsisText className="h1-typography" text="Edit Register" />
            </>
          )}
        </div>
        <Space className="d-flex justify-end">
          <Dropdown
            getPopupContainer={() =>
              // eslint-disable-next-line no-undef
              document.querySelector('.details-header')
            }
            menu={{
              items: [
                {
                  key: 'csv',
                  label: renderDownloadCsvButton(),
                },
              ],
            }}
            trigger={['click']}
            placement="bottom"
          >
            <Button
              shape="round"
              type="primary"
              icon={<Export height="26px" />}
              className="fill-white d-flex b-0"
            >
              {isDesktopViewport && 'Export'}
            </Button>
          </Dropdown>
        </Space>
      </div>
      <div className={clsx(isDesktopViewport ? 'mt-25' : 'p-14')}>
        <RegisterPropertyDetails
          createRegisterData={createRegisterData}
          registerDetailsData={registerDetailsData}
        />
      </div>
    </div>
  );
};

export default RegisterDetailsContent;
