import { useLazyQuery } from '@apollo/client';
import { Checkbox, Col, Descriptions, Row, Tag } from 'antd';
import clsx from 'clsx';
import { map, omit } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { Timezone } from '../../../assets/svg';
import {
  BREAKPOINTS,
  DATETIMEWITHDIVIDE,
  DEFAULTDATEFORMAT,
  DEFAULT_PAGE_SIZE,
  INS_STATUS_CLASSNAME,
  INS_STATUS_KEYS,
  INS_STATUS_LABEL,
  ROUTES,
} from '../../../common/constants';
import useRouter from '../../../common/useRouter';
import CommonCard from '../../../components/CommonCard';
import CommonDropdown from '../../../components/CommonDropdown';
import CommonTable from '../../../components/CommonTable';
import EllipsisText from '../../../components/EllipsisText';
import InfiniteScrollHandler from '../../../components/InfiniteScrollHandler';
import Portal from '../../../components/Portal';
import SearchComponent from '../../../components/SearchComponent';
import { GET_PROJECT_DROPDOWN_LIST } from '../../projects/graphql/Queries';
import { USER_INSTRUCTION_TODO_LIST } from '../graphql/Queries';

const IssuesList = () => {
  const { navigate, location } = useRouter();
  const navFilter = location?.state?.navFilter;
  const navPagination = location?.state?.navPagination;
  const initialProjectFilter = {
    skip: 0,
    limit: 10,
    sortBy: { field: 'createdAt', order: 'DESC' },
  };
  const initialPaginationValue = {
    total: 0,
    current: 1,
  };
  const [paginationProp, setPaginationProp] = useState(
    navPagination || initialPaginationValue,
  );
  const [selectedProjectRecord, setSelectedProjectRecord] = useState();
  const [projectFilter, setProjectFilter] = useState(
    navFilter || initialProjectFilter,
  );
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [hasMore, setHasMore] = useState(true);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [instructionListData, setInstructionListData] = useState([]);
  const [tenantId, setTenantId] = useState(projectFilter?.tenantId);

  const [type, setType] = useState(projectFilter?.type);
  const [projectId, setProjectId] = useState(projectFilter?.projectId);
  const [isOverdue, setIsOverdue] = useState(projectFilter?.isOverdue);

  const [selectedTags, setSelectedTags] = useState(projectFilter?.tags);

  useEffect(() => {
    if (navFilter) {
      setProjectFilter(navFilter);
    }
    if (navFilter?.isOverdue) {
      setIsOverdue(navFilter?.isOverdue);
    }
    if (navFilter?.tags?.length > 0) {
      setSelectedTags(navFilter?.tags);
    }
    if (navFilter?.projectId) {
      setProjectId(navFilter?.projectId);
    }
    if (navFilter?.type) {
      setType(navFilter?.type);
    }
    if (navFilter?.tenantId) {
      setTenantId(navFilter?.tenantId);
    }
    if (navPagination) {
      setPaginationProp(navPagination);
    }
  }, [navFilter, navPagination]);

  const [fetchInstructionList, { loading }] = useLazyQuery(
    USER_INSTRUCTION_TODO_LIST,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const data = res?.userInstructionTodoList?.data || [];
        const pagination = {
          ...paginationProp,
          defaultPageSize: DEFAULT_PAGE_SIZE,
          total: res?.userInstructionTodoList?.total,
        };
        if (scrollFlag) {
          const datalist = [...instructionListData, ...data];
          setInstructionListData(datalist);
          setScrollFlag(false);
        } else {
          const datalist = [...data];
          setInstructionListData(datalist);
        }
        setHasMore(!!data?.length);
        setPaginationProp(pagination);
      },
      onError() {
        if (isDesktopViewport) {
          setInstructionListData([]);
        }
      },
    },
  );
  useEffect(() => {
    fetchInstructionList({
      variables: { filter: { ...projectFilter } },
    });
    if (location?.state) {
      // eslint-disable-next-line no-undef
      window.history.replaceState({}, document.title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectFilter]);

  useEffect(() => {
    let newFilter = {
      ...projectFilter,
      type,
      tags: selectedTags,
      projectId,
      tenantId,
      skip: projectFilter?.skip !== 0 ? projectFilter?.skip : 0,
    };
    if (isOverdue) {
      newFilter.isOverdue = isOverdue;
    } else {
      newFilter = omit(newFilter, 'isOverdue');
    }
    setPaginationProp({
      ...paginationProp,
      current: paginationProp?.current !== 1 ? paginationProp?.current : 1,
    });
    setProjectFilter(newFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantId, projectId, type, isOverdue, selectedTags]);

  const getStatus = (record) => {
    return (
      <Tag className={clsx(INS_STATUS_CLASSNAME[record?.status], 'status-tag')}>
        {INS_STATUS_LABEL[record?.status]}
      </Tag>
    );
  };
  const getDueDate = (record) => {
    const isOverDue = record?.isOverdue;
    return record?.isNotice || !record?.reminderDate ? (
      '-'
    ) : (
      <div className={isOverDue ? 'text-danger' : ''}>
        {
          // eslint-disable-next-line no-nested-ternary
          record?.status === INS_STATUS_KEYS?.CLOSED && isOverDue
            ? ''
            : isOverDue
              ? ` Due since ${moment(record?.reminderDate).from(moment(), true)}`
              : `Due in ${moment(record?.reminderDate).from(
                  moment(record?.createdAt),
                  true,
                )}`
        }
        <div>
          {moment(record?.reminderDate)
            .tz(record?.project?.timeZone)
            .format(DATETIMEWITHDIVIDE)}
          <div className="d-flex align-center timezone-div">
            <Timezone title="timeZone" />
            {record?.project?.timeZone}
          </div>
        </div>
      </div>
    );
  };
  const getDueStatus = (record) => {
    const isOverDue = record?.isOverdue;
    if (record?.status === INS_STATUS_KEYS?.CLOSED) {
      return '-';
    }
    if (record?.reminderDate) {
      return isOverDue ? (
        <div className="text-danger">
          Due since
          {` ${moment(record?.reminderDate).from(moment(), true)}`}
        </div>
      ) : (
        <div>
          Due in
          {` ${moment(record?.reminderDate).from(
            moment(record?.createdAt),
            true,
          )}`}
        </div>
      );
    }
    return '-';
  };
  const getUpdatedBy = (record) => {
    if (record?.status === INS_STATUS_KEYS.RESPONDED) {
      return <EllipsisText text={record?.responderUser?.name || '-'} />;
    }
    return <EllipsisText text={record?.modifier?.name || '-'} />;
  };
  const getTypeName = (record) => {
    return record?.type || '-';
  };
  const columns = [
    //! commented for future use.
    // {
    //   title: '#',
    //   key: 'id',
    //   render: (text, record, index) => {
    //     return (
    //       <div className="d-flex align-center">
    //         <span>{projectFilter?.skip + index + 1}</span>
    //         <span className="ml-5 d-flex align-center">
    //           {record?.status === INS_STATUS_KEYS.NOTICE && (
    //             <Tooltip title="Notice">
    //               <InfoIconDark height="20px" width="20px" />
    //             </Tooltip>
    //           )}
    //         </span>
    //       </div>
    //     );
    //   }
    // },
    {
      title: 'STATUS',
      key: 'status',
      render: (users, record) => getStatus(record),
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
      render: (text) => (
        <div>
          <EllipsisText className="fw-medium" text={text || '-'} />
        </div>
      ),
    },
    {
      title: 'RAISED BY',
      key: 'updatedBy',
      dataIndex: 'updatedBy',
      render: (text, record) => getUpdatedBy(record),
    },
    {
      title: 'RAISED ON',
      key: 'createdAt',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'createdAt' && sortedInfo?.order,
      render: (createdAt, record) => {
        return record?.createdAt ? (
          <div>
            {moment(record?.createdAt)
              .tz(record?.project?.timeZone)
              .format(DEFAULTDATEFORMAT)}
            <div className="d-flex align-center timezone-div">
              <Timezone title="timeZone" />
              {record?.project?.timeZone}
            </div>
          </div>
        ) : (
          '-'
        );
      },
    },
    {
      title: 'DEADLINE',
      key: 'reminderDate',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'reminderDate' && sortedInfo?.order,
      render: (dueDate, record) => getDueDate(record),
    },
    {
      title: 'TYPE',
      dataIndex: 'type',
      key: 'type',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'type' && sortedInfo?.order,
      render: (text, record) => getTypeName(record),
    },
    {
      title: 'TAGS',
      dataIndex: 'tags',
      key: 'tags',
      render: (tags) => (
        <div>
          {tags?.length > 0
            ? tags?.map((tag) => <div key={tag}>{`#${tag}`}</div>)
            : '-'}
        </div>
      ),
    },
  ];
  const onSearchChange = async (value) => {
    setPaginationProp(initialPaginationValue);
    setProjectFilter({ ...projectFilter, skip: 0, search: value });
  };
  const handleRowClick = (record) => {
    if (record?.id) {
      navigate(`${ROUTES.TODO}${ROUTES.ISSUES}/${record?.id}`, {
        state: {
          navFilter: projectFilter,
          navPagination: paginationProp,
          currentPath: location?.pathname,
        },
      });
    }
  };

  const handleRefetch = () => {
    fetchInstructionList({
      variables: {
        filter: {
          ...projectFilter,
          skip: instructionListData?.length,
        },
      },
    });
  };
  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setPaginationProp({ ...paginationProp, ...pagination });
    setSortedInfo(sorter);
    if (sorter?.column) {
      setProjectFilter({
        ...projectFilter,
        skip,
        limit: pagination.pageSize,
        sortBy: {
          field: sorter.columnKey,
          order: sorter.order === 'ascend' ? 'ASC' : 'DESC',
        },
      });
    } else {
      setProjectFilter({
        ...projectFilter,
        skip,
        limit: pagination.pageSize,
        sortBy: { field: 'createdAt', order: 'DESC' },
      });
    }
  };

  const onProjectChange = (id, record) => {
    setPaginationProp({ ...paginationProp, current: 1 });
    setSelectedProjectRecord(record);
    setProjectId(id);

    setProjectFilter({
      ...projectFilter,
      projectId: Number(id),
      skip: 0,
    });
    fetchInstructionList({
      variables: {
        filter: {
          ...projectFilter,
          projectId: Number(id),
          skip: 0,
        },
      },
    });
  };

  //! for future use
  // const handleClearFilter = () => {
  //   setProjectId();
  //   setTenantId();
  //   setType();
  //   setSelectedTags([]);
  //   setProjectFilter({ ...projectFilter, search: '' });
  //   setIsOverdue(false);
  // };

  // const EmptyState = !loading && (
  //   <Empty
  //     image={TrophyInHand}
  //     imageStyle={{
  //       height: 148,
  //     }}
  //     description={
  //       <div>
  //         <div className="empty-text">You are up to date!</div>
  //         {(tenantId ||
  //           projectId ||
  //           isOverdue ||
  //           type ||
  //           projectFilter?.search) &&
  //         !loading ? (
  //           <div className="empty-text-secondary">
  //             Filter applied, no data found.
  //             <Button
  //               type="link"
  //               className="clear-filter-button"
  //               onClick={handleClearFilter}
  //             >
  //               Clear Filter.
  //             </Button>
  //           </div>
  //         ) : (
  //           <div className="empty-text-secondary">No data found.</div>
  //         )}
  //       </div>
  //     }
  //   />
  // );

  return (
    <>
      <Portal portalId="search-component">
        <div className={isDesktopViewport ? 'd-flex' : 'width-percent-100'}>
          <Row
            className="d-flex filter-search"
            justify={isDesktopViewport ? 'end' : 'start'}
            align="middle"
            wrap
            gutter={isDesktopViewport ? [10, 10] : [0, 10]}
          >
            <Col span={isDesktopViewport ? null : 12}>
              <Checkbox
                onChange={(event) => {
                  setIsOverdue(event?.target?.checked);
                  setProjectFilter({ ...projectFilter, skip: 0 });
                  setPaginationProp({ ...paginationProp, current: 1 });
                }}
                checked={isOverdue}
              >
                Overdue
              </Checkbox>
            </Col>
            <Col span={isDesktopViewport ? null : 24}>
              <CommonDropdown
                allowClear
                placeholder="Project"
                className={`instruction-selector dropdown-width-auto ${
                  isDesktopViewport ? 'width-200' : 'width-percent-100'
                } `}
                value={projectId}
                onChange={onProjectChange}
                query={GET_PROJECT_DROPDOWN_LIST}
                fetchPolicy="network-only"
                responsePath="projectDropdownList.data"
                valuePath="id"
                labelPath="name"
                optionKey="project"
                showSearch
                optionFilterProp="children"
                customOptions={selectedProjectRecord}
                dropdownMatchSelectWidth={false}
              />
            </Col>
            <Col span={isDesktopViewport ? null : 24}>
              <SearchComponent
                id="search-container-id "
                className={clsx(
                  'search-component',
                  projectFilter?.search && 'focused-search',
                )}
                getData={onSearchChange}
                defaultValue={projectFilter?.search}
                name="issue"
              />
            </Col>
          </Row>
        </div>
      </Portal>
      <div className="pointer todo-list">
        {isDesktopViewport ? (
          <CommonTable
            rowClassName={(record) => {
              if (record?.isActive === false) return 'deactivated-color';
            }}
            columns={columns}
            data={instructionListData}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
            loading={loading}
            rowKey={(obj) => obj?.id}
            onRow={(record) => {
              return {
                onClick: () => handleRowClick(record),
              };
            }}
          />
        ) : (
          <InfiniteScrollHandler
            scrollFlag={scrollFlag}
            refetchData={handleRefetch}
            setScrollFlag={setScrollFlag}
            hasMore={hasMore}
            dataLength={instructionListData?.length}
            skeletonRows={columns?.length - 3}
            loading={loading}
          >
            {map(instructionListData, (record) => {
              return (
                <CommonCard
                  key={record?.id}
                  onClick={() => handleRowClick(record)}
                >
                  <Row justify="space-between" align="middle">
                    <Col>{getStatus(record)}</Col>
                    <Col>{getDueStatus(record)}</Col>
                  </Row>

                  <EllipsisText
                    className="fw-medium mt-10"
                    text={record?.name || '-'}
                  />
                  <Descriptions layout="vertical" column={2} className="mt-6">
                    <Descriptions.Item label="Raised By">
                      <div>{getUpdatedBy(record)}</div>
                    </Descriptions.Item>
                    <Descriptions.Item label="Raised On">
                      <div>
                        {record?.createdAt ? (
                          <div>
                            {moment(record?.createdAt)
                              .tz(record?.project?.timeZone)
                              .format(DEFAULTDATEFORMAT)}
                            <div className="d-flex align-center timezone-div">
                              <Timezone title="timeZone" />
                              {record?.project?.timeZone}
                            </div>
                          </div>
                        ) : (
                          '-'
                        )}
                      </div>
                    </Descriptions.Item>
                    <Descriptions.Item label="Deadline">
                      <div>{getDueDate(record)}</div>
                    </Descriptions.Item>
                    <Descriptions.Item label="Type">
                      <div>{record?.type || '-'}</div>
                    </Descriptions.Item>
                    <Descriptions.Item label="Tags">
                      <div>{record?.tags?.join(', ') || '-'}</div>
                      <div>
                        {record?.tags?.length > 0
                          ? record?.tags?.map((tag) => (
                              <div key={tag}>{`#${tag}`}</div>
                            ))
                          : '-'}
                      </div>
                    </Descriptions.Item>
                  </Descriptions>
                </CommonCard>
              );
            })}
          </InfiniteScrollHandler>
        )}
      </div>
    </>
  );
};

export default IssuesList;
