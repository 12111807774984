import { useMutation } from '@apollo/client';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Switch,
} from 'antd';
import { camelCase, filter, keys, map, toLower, upperCase } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { DeleteButton } from '../../../../../../assets/svg';
import {
  BREAKPOINTS,
  FIELD_TYPES,
  FIELD_TYPES_LABELS,
} from '../../../../../../common/constants';
import useRouter from '../../../../../../common/useRouter';
import { formValidatorRules } from '../../../../../../common/utils';
import CommonSelect from '../../../../../../components/CommonSelect';
import {
  CREATE_REGISTER_FORM_FIELD,
  UPDATE_REGISTER_FORM_FIELD,
} from '../../../../graphql/Mutation';

const { required, characterWithoutWhiteSpace } = formValidatorRules;

const OptionUi = (isDesktopViewport, isDisabled) => {
  return (
    <>
      <Form.List name="options">
        {(fields, { add, remove }) => {
          return (
            <>
              <Row gutter={[15, 15]} className="width-percent-100">
                {fields.map((field) => (
                  <Col span={24} key={field?.key}>
                    <Row gutter={[30]}>
                      <Col flex="auto">
                        <Form.Item
                          {...field}
                          label="Value"
                          name={[field.name, 'option']}
                          fieldKey={[field?.fieldKey, 'option']}
                          validateTrigger={['onChange', 'onClick']}
                          rules={[
                            required,
                            characterWithoutWhiteSpace,
                            ({ getFieldValue }) => ({
                              validator(rule, value) {
                                const items = map(
                                  getFieldValue()?.options,
                                  (item) => item?.option,
                                );
                                if (
                                  filter(
                                    items,
                                    (val) => toLower(val) === toLower(value),
                                  )?.length > 1 &&
                                  value
                                ) {
                                  // eslint-disable-next-line prefer-promise-reject-errors
                                  return Promise.reject(
                                    'should be an unique option',
                                  );
                                }
                                return Promise.resolve();
                              },
                            }),
                          ]}
                        >
                          <Input
                            placeholder="Enter Value"
                            disabled={isDisabled}
                          />
                        </Form.Item>
                      </Col>
                      <Col className="d-flex align-center">
                        {fields?.length > 2 && (
                          <Form.Item
                            label={isDesktopViewport && ' '}
                            className="mb-0"
                          >
                            <DeleteButton
                              className="pointer"
                              onClick={() => {
                                if (!isDisabled && fields?.length > 2)
                                  remove(field?.name);
                              }}
                            />
                          </Form.Item>
                        )}
                      </Col>
                    </Row>
                  </Col>
                ))}
                <Row>
                  <Col className="mb-15">
                    <Button
                      className="grey-button"
                      shape="round"
                      disabled={isDisabled}
                      onClick={() => {
                        add();
                      }}
                    >
                      Add Option
                    </Button>
                  </Col>
                </Row>
              </Row>
            </>
          );
        }}
      </Form.List>
    </>
  );
};

// Commented for future use
// const CalculationLogicUi = (isDesktopViewport, numericFields, form) => {
//   const operators = ['+', '-', '*', '/', '(', ')'];
//   const handleButtonClick = (fieldTitle) => {
//     const currentFormula = form.getFieldValue('formula') || '';
//     form?.setFieldsValue({
//       formula: `${currentFormula}"${fieldTitle}"`,
//     });
//   };

// Commented for future use
//   const handleOperatorClick = (operator) => {
//     const currentFormula = form.getFieldValue('formula') || '';
//     let newOperator = operator;

//     if (['+', '-', '*', '/'].includes(operator)) {
//       newOperator = ` ${operator} `;
//     }

// Commented for future use
//     form?.setFieldsValue({ formula: currentFormula + newOperator });
//   };

// Commented for future use
//   return (
//     <>
//       <Row gutter={[20, 20]}>
//         <Col span={24}>
//           <Form.Item
//             name="formula"
//             label="Formula"
//             className="numeric-input"
//             rules={[
//               required,
//               characterWithoutWhiteSpace,
//               () => ({
//                 validator(_, value) {
//                   if (!value) {
//                     return Promise.reject(new Error('This field is required!'));
//                   }
//                   try {
//                     const formulaWithTestValues = value.replace(
//                       /"[^"]+"/g,
//                       '1',
//                     );
//                     evaluate(formulaWithTestValues);
//                     return Promise.resolve();
//                   } catch (error) {
//                     return Promise.reject(
//                       new Error('Please enter a valid formula'),
//                     );
//                   }
//                 },
//               }),
//             ]}
//           >
//             <Input
//               placeholder="Enter Value"
//               allowClear
//               onKeyDown={(e) => {
//                 if (e.key !== 'Backspace') {
//                   e.preventDefault();
//                   return;
//                 }
//                 const currentFormula = form?.getFieldsValue('formula') || '';
//                 if (currentFormula) {
//                   const formulaParts = currentFormula?.formula
//                     ?.trim()
//                     ?.split(/\s+/);
//                   formulaParts?.pop();
//                   const newFormula = formulaParts?.join(' ');
//                   form?.setFieldsValue({ formula: newFormula });
//                 }
//               }}
//               disabled={numericFields?.length === 0}
//             />
//           </Form.Item>
//           {/* Note is to be shown */}
//           <div className="note">
//             {numericFields?.length > 0
//               ? "Enter valid formula using field names (Eg. 'field 1' + 'field 2')"
//               : 'Add at least one numeric field to enable calculations'}
//           </div>
//           <h4 className="mb-10">Fields</h4>
//           {numericFields?.length > 0 ? (
//             <div className="d-flex mb-10 flex-wrap">
//               {map(numericFields, (field, index) => (
//                 <>
//                   <div key={index} className="d-flex flex-wrap">
//                     <Button
//                       shape="round"
//                       className="cancel-button mr-10 mb-10"
//                       onClick={() => handleButtonClick(field?.name)}
//                     >
//                       {field?.name}
//                     </Button>
//                   </div>
//                 </>
//               ))}
//             </div>
//           ) : (
//             <div className="text-danger note-text mt-10 mb-10">
//               Note: Please add numeric fields to set the formula.
//             </div>
//           )}
//           <h4>Operators</h4>
//           <div className="d-flex">
//             {map(operators, (operator) => (
//               <Button
//                 key={operator}
//                 shape="round"
//                 className="d-flex align-center save-button mr-10"
//                 onClick={() => handleOperatorClick(operator)}
//                 disabled={numericFields?.length === 0}
//               >
//                 {operator}
//               </Button>
//             ))}
//           </div>
//         </Col>
//       </Row>
//     </>
//   );
// };

const AddFieldModal = ({
  createRegisterData,
  showModal,
  setShowModal,
  // numericFields, --> Commented for future use
  tableHeaders,
  setTableHeaders,
  isDisabled = false,
  selectedField,
  isUpdate,
  setSelectedField,
  handleRefetch,
}) => {
  const {
    params: { registerId },
  } = useRouter();

  const [form] = Form.useForm();
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [displayOption, setDisplayOption] = useState(false);
  const [isOptionType, setIsOptionType] = useState(false);
  const [displayOptionValue, setDisplayOptionValue] = useState(
    OptionUi(isDesktopViewport),
  );
  const [createRegisterFormField, { loading: createLoading }] = useMutation(
    CREATE_REGISTER_FORM_FIELD,
    {
      onError() {},
      onCompleted: () => {
        setShowModal(false);
        handleRefetch();
      },
    },
  );
  const [updateRegisterFormField, { loading: updateLoading }] = useMutation(
    UPDATE_REGISTER_FORM_FIELD,
    {
      onError() {},
      onCompleted: () => {
        setShowModal(false);
        handleRefetch();
      },
    },
  );

  const onFinish = () => {
    const formValues = form.getFieldsValue();
    const options = formValues?.options?.map((value) => value?.option);

    // Commented for future use
    // if (formValues?.type === FIELD_TYPES.CALCULATION) {
    //   formValues.options = [formValues?.formula];
    //   let formulaValue = formValues?.formula;
    //   const regex = /"([^"]*)"/g;
    //   formulaValue = formulaValue.replace(regex, (match, name) => {
    //     const field = numericFields.find((f) => f.name === name);
    //     return field ? `"${field.id}"` : match;
    //   });
    //   delete newFormValues.formula;
    //   newFormValues.options = [formulaValue];
    // }
    if (formValues?.options?.length > 0) {
      formValues.options = formValues?.options?.map((value) => value?.option);
    }

    if (!createRegisterData && isUpdate) {
      updateRegisterFormField({
        variables: {
          updateRegisterFormFieldId: selectedField?.id,
          data: {
            name: formValues?.name,
            isRequired: formValues?.isRequired,
            type: formValues?.type,
            options: options || null,
            sequence: tableHeaders?.length + 1,
          },
        },
      });
    } else if (!createRegisterData) {
      createRegisterFormField({
        variables: {
          data: {
            registerId,
            ...formValues,
            options: options || null,
            sequence: tableHeaders?.length + 1,
          },
        },
      });
    } else {
      formValues.tempId = `temp_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
      if (selectedField?.tempId) {
        const nameExists = tableHeaders?.some(
          (item) =>
            item?.name === formValues?.name &&
            item?.tempId !== selectedField?.tempId,
        );
        if (nameExists) {
          message.destroy();
          message.error(`Field name "${formValues?.name}" already exists`);
        } else {
          formValues.tempId = selectedField?.tempId;
          const updatedFields = tableHeaders.map((prop) =>
            prop.tempId === selectedField.tempId ? formValues : prop,
          );
          setTableHeaders(updatedFields);
          setShowModal(false);
        }
      } else if (
        tableHeaders?.find((item) => item?.name === formValues?.name)
      ) {
        message.destroy();
        message.error(`Field name "${formValues?.name}" already exists.`);
      } else {
        setTableHeaders([
          ...tableHeaders,
          {
            name: upperCase(formValues?.name),
            dataIndex: camelCase(formValues?.name),
            index: camelCase(formValues?.name),
            isRequired: formValues?.isRequired,
            type: formValues?.type,
            options: options || null,
            sequence: tableHeaders?.length + 1,
            ...formValues,
          },
        ]);
        setShowModal(false);
      }
    }
  };

  const handleTypeChange = (options) => {
    switch (options?.value) {
      case FIELD_TYPES.OPTIONS:
        setDisplayOption(true);
        setIsOptionType(true);
        setDisplayOptionValue(OptionUi(isDesktopViewport));
        break;

      case FIELD_TYPES.CHECKBOXES:
        setDisplayOption(true);
        setIsOptionType(true);
        setDisplayOptionValue(OptionUi(isDesktopViewport));
        break;

      // Commented for future use
      // case FIELD_TYPES.CALCULATION:
      //   setDisplayOption(true);
      //   setIsOptionType(false);
      //   setDisplayOptionValue(
      //     CalculationLogicUi(isDesktopViewport, numericFields, form),
      //   );
      //   break;

      default:
        setDisplayOption(false);
        setIsOptionType(false);
        break;
    }
  };
  useEffect(() => {
    if (isUpdate && selectedField) {
      handleTypeChange({ value: selectedField?.type });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal, selectedField]);
  const handleSelect = (labeledValue, options) => {
    handleTypeChange(options);
    if (labeledValue === FIELD_TYPES.OPTIONS) {
      form.setFieldsValue({ options: [{}, {}] });
    } else if (labeledValue === FIELD_TYPES.CHECKBOXES) {
      form.setFieldsValue({ options: [{}, {}] });
    }
  };

  useEffect(() => {
    if (isUpdate && showModal && selectedField) {
      // Commented for future use
      // const formattedOptions =
      //   selectedField?.type !== FIELD_TYPES.CALCULATION
      //     ? selectedField.options?.map((option) => ({
      //         option,
      //       }))
      //     : [];
      // const formula =
      //   selectedField?.type === FIELD_TYPES.CALCULATION
      //     ? selectedField.options?.[0]
      //     : '';
      const transformedValues = {
        ...selectedField,
        options: selectedField?.options?.map((option) => ({ option })) || null,
      };
      // Commented for future use
      // form.setFieldsValue({
      //   ...selectedField,
      //   options: formattedOptions,
      // });
      form.setFieldsValue(transformedValues);
    }
  }, [showModal, selectedField, isUpdate]);

  return (
    <div>
      <Modal
        open={showModal}
        footer={false}
        centered
        className="register-table-modal"
        maskClosable={false}
        closable={false}
        form={form}
        destroyOnClose
      >
        <h2 className="mb-15">{isUpdate ? 'Edit ' : 'Add '} Field</h2>
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            type: 'TEXT',
            isRequired: true,
            options: [{}, {}],
          }}
          className="mt-16"
          onFinish={onFinish}
        >
          <Form.Item
            rules={[
              required,
              characterWithoutWhiteSpace,
              { max: 250, message: 'Name cannot be more than 250 characters' },
            ]}
            name="name"
            label="Property Name"
          >
            <Input
              placeholder="Enter property name"
              disabled={isDisabled}
              allowClear
            />
          </Form.Item>
          <Row gutter={30}>
            <Col span={isDesktopViewport ? 18 : 24}>
              <Form.Item name="type" label="Type">
                <CommonSelect
                  className="mr-3"
                  placeholder="Select Type"
                  onSelect={handleSelect}
                  options={[
                    ...keys(FIELD_TYPES).map((type) => {
                      return {
                        key: FIELD_TYPES_LABELS[type],
                        value: type,
                        label: FIELD_TYPES_LABELS[type],
                      };
                    }),
                  ]}
                  disabled={isDisabled}
                />
              </Form.Item>
            </Col>
            <Col span={isDesktopViewport ? 6 : 24}>
              <Form.Item
                name="isRequired"
                label="Required"
                valuePropName="checked"
              >
                <Switch defaultChecked disabled={isDisabled} />
              </Form.Item>
            </Col>
          </Row>
          {displayOption && (
            <>
              <Divider dashed />
              {isOptionType ? <h3>Options</h3> : <h3>Calculation</h3>}
              <div className="mt-10">{displayOptionValue}</div>
            </>
          )}
          <div className="form-buttons">
            <Button
              shape="round"
              className="cancel-button"
              disabled={createLoading || updateLoading}
              onClick={() => {
                setShowModal(false);
                setSelectedField();
              }}
            >
              Cancel
            </Button>
            <Button
              shape="round"
              type="primary"
              className="save-button"
              htmlType="submit"
              loading={createLoading || updateLoading}
            >
              Save
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default AddFieldModal;
