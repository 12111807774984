import { useLazyQuery } from '@apollo/client';
import { Menu, Space } from 'antd';
import clsx from 'clsx';
import { map } from 'lodash';
import React, { useContext, useMemo, useState } from 'react';
import { AppContext } from '../../../AppContext';
import alternateLogo from '../../../assets/images/alternateLogo.png';
import { DownArrow } from '../../../assets/svg';
import { ROUTES } from '../../../common/constants';
import useRouter from '../../../common/useRouter';
import CommonPopover from '../../../components/CommonPopover';
import { GET_PROFILE } from '../../../modules/users/graphql/Queries';

const TenantSelect = ({ userData }) => {
  const { getTenantData, getCurrentTenant, changeTenant, initializeAuth } =
    useContext(AppContext);

  const [visible, setVisible] = useState(false);

  const [getUserProfile] = useLazyQuery(GET_PROFILE, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      initializeAuth('', '', res?.getLoggedInUser);
    },
    onError: () => {},
  });
  const { navigate } = useRouter();
  const currentTenant = getCurrentTenant();
  const tenantData = getTenantData();

  useMemo(() => {
    if (!currentTenant && tenantData?.length > 0) {
      changeTenant(tenantData?.[0]?.tenant?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantData]);

  const handleChange = (value) => {
    changeTenant(value?.key);
    getUserProfile();
    navigate(ROUTES?.MAIN);
    setVisible(false);
  };

  const handleVisibleChange = (value) => {
    setVisible(value);
  };
  return (
    <div
      className={clsx(
        'gx-avatar-row header-right p-0',
        tenantData?.length > 1 && 'pointer',
      )}
    >
      <div className="filter-wrapper">
        <CommonPopover
          className={clsx(
            'flex-row user-profile',
            tenantData?.length > 1 && 'multi-tenant-dropdown',
          )}
          placement="bottomRight"
          content={
            tenantData?.length > 1 ? (
              <Menu
                selectedKeys={currentTenant || tenantData?.[0]?.tenant?.id}
                items={[
                  ...map(tenantData, (tenant) => {
                    return {
                      key: tenant?.tenant?.id,
                      value: tenant?.tenant?.id,
                      label: tenant?.tenant?.organizationName,
                    };
                  }),
                ]}
                onClick={handleChange}
              />
            ) : null
          }
          trigger="click"
          open={visible}
          onOpenChange={handleVisibleChange}
          getTooltipContainer={(triggerNode) => triggerNode?.parentNode}
          destroyTooltipOnHide
        >
          <Space direction="horizontal">
            <img
              src={
                userData?.getLoggedInUser?.tenantUser?.tenant?.logo ||
                alternateLogo
              }
              className="profileImage gx-pointer mr-0 ml-0"
              alt="Avatar"
            />
            {tenantData?.length > 1 && <DownArrow />}
          </Space>
        </CommonPopover>
        {/* <GlobeIcon />
        <Divider type="vertical" />
        <CommonSelect
          className="width-200"
          onChange={handleChange}
          defaultValue={currentTenant || tenantData?.[0]?.tenant?.id}
          options={[
            ...map(tenantData, (tenant) => {
              return {
                key: tenant?.tenant?.id,
                value: tenant?.tenant?.id,
                label: tenant?.tenant?.organizationName,
              };
            }),
          ]}
        /> */}
      </div>
    </div>
  );
};

export default TenantSelect;
