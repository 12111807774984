import { useLazyQuery, useMutation } from '@apollo/client';
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Descriptions, Divider, Dropdown } from 'antd';
import clsx from 'clsx';
import { groupBy, includes, map, sortBy } from 'lodash';
import React, {
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../../../../../../../AppContext';
import {
  AddButton,
  DeleteButton,
  DragIcon,
  DuplicateIcon,
  EditButton,
  KebabMenu,
  PersonDone,
} from '../../../../../../../../assets/svg';
import {
  ACCESS_TYPE,
  ALLOWED_ACTION_KEYS,
  ALLOWED_ACTION_TYPE,
  BREAKPOINTS,
  DEFAULT_PAGE_SIZE,
  EQC_STAGE_CONFIG_LABEL,
  GA_EVENT,
  GA_LABEL,
  PROJECT_CHECKLIST_STATUS,
  REQUEST_FEATURE_UPGRADE_KEYS,
} from '../../../../../../../../common/constants';
import { Event } from '../../../../../../../../common/trackEvents';
import useRouter from '../../../../../../../../common/useRouter';
import CanPerform from '../../../../../../../../components/CanPerform';
import CommonPopover from '../../../../../../../../components/CommonPopover';
import CommonTable from '../../../../../../../../components/CommonTable';
import CommonTooltip from '../../../../../../../../components/CommonTooltip';
import EllipsisText from '../../../../../../../../components/EllipsisText';
import Portal from '../../../../../../../../components/Portal';
import UpgradeModal from '../../../../../../../../components/UpgradeModal';
import {
  DELETE_PROJECT_STAGE,
  REORDER_PROJECT_STAGE,
  UPDATE_PROJECT_EQC_STAGE,
} from '../../../../../../graphql/Mutation';
import { GET_PROJECT_STAGE_LIST } from '../../../../../../graphql/Queries';
import ApproversDrawer from '../../ApproversDrawer';
import DuplicateEqcTypeModal from '../../DuplicateEqcType';
import DeleteModalStage from '../DeleteModalStage';
import AddEditStageModal from './AddEditStageModal';

const RowContext = React.createContext({});
const Rows = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    // eslint-disable-next-line react/destructuring-assignment
    id: props['data-row-key'], // ----> getting id
  });
  const style = {
    // eslint-disable-next-line react/destructuring-assignment
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging
      ? {
          position: 'relative',
          zIndex: 9999,
        }
      : {}),
  };
  const contextValue = useMemo(
    () => ({
      setActivatorNodeRef,
      listeners,
    }),
    [setActivatorNodeRef, listeners],
  );
  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};
const DragHandle = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <DragIcon
      style={{
        cursor: 'move',
      }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

const StageListTable = ({
  setStageId,
  setTitle,
  stageId,
  eqcTypeData,
  isSequenceChanged,
  setIsSequenceChanged,
  setStageData: setSelectedStageData,
  isEditable,
  refetchRef,
}) => {
  const {
    params: { eqcTypeId, projectId },
  } = useRouter();
  const initialStageFilter = {
    skip: 0,
    limit: 10,
    projectEqcTypeId: Number(eqcTypeId),
    sortBy: { field: 'order', order: 'ASC' },
  };

  const initialPaginationValue = {
    total: 0,
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  };
  const { getCurrentUser, getTenantUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const currentTenant = getTenantUser();
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [showModal, setShowModal] = useState(false);
  const [deleteStageModal, setDeleteStageModal] = useState(false);
  const [stageData, setStageData] = useState();
  const [selectedData, setSelectedData] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [stageFilter, setStageFilter] = useState(initialStageFilter);
  const [open, setOpen] = useState(false);
  const [drawerData, setDrawerData] = useState();
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [activeStageCount, setActiveStageCount] = useState(0);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);
  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);

  const [fetchStageData, { loading }] = useLazyQuery(GET_PROJECT_STAGE_LIST, {
    variables: { filter: stageFilter },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setSelectedStageData(res?.projectEqcTypeStageList?.data);
      setActiveStageCount(res?.projectEqcTypeStageList?.activeStageCount);
      const pagination = {
        ...paginationProp,
        total: res?.projectEqcTypeStageList?.total,
      };
      setPaginationProp(pagination);
      setDataSource(sortBy(res?.projectEqcTypeStageList?.data, ['order']));
      if (isSequenceChanged) {
        setStageId(stageId);
        setIsSequenceChanged(false);
      }
    },
    onError() {},
  });

  useEffect(() => {
    if (isSequenceChanged) {
      fetchStageData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSequenceChanged]);

  const handleRefetchAfterDelete = () => {
    const newSkip =
      dataSource?.length === 1
        ? Math.max(0, stageFilter?.skip - paginationProp?.pageSize)
        : stageFilter?.skip;
    setStageFilter({
      ...stageFilter,
      skip: newSkip,
    });
    setSelectedStageData();
    fetchStageData({
      variables: {
        filter: {
          ...stageFilter,
          skip: newSkip,
        },
      },
    });
  };
  const [updateProjectEqcTypeStage] = useMutation(UPDATE_PROJECT_EQC_STAGE, {
    onError() {},
    onCompleted() {
      Event(GA_EVENT.DELETE_PROJECT_CHECKLIST_STAGE, {
        label: GA_LABEL.DELETE_PROJECT_CHECKLIST_STAGE,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        project_id: projectId,
        project_checklist_id: eqcTypeId,
        project_stage_id: selectedData?.id,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
      });
      setDeleteStageModal(false);
      handleRefetchAfterDelete();
    },
  });

  const [deleteStage] = useMutation(DELETE_PROJECT_STAGE, {
    onError() {},
    onCompleted() {
      Event(GA_EVENT.DELETE_PROJECT_CHECKLIST_STAGE, {
        label: GA_LABEL.DELETE_PROJECT_CHECKLIST_STAGE,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        project_id: projectId,
        project_checklist_id: eqcTypeId,
        project_stage_id: selectedData?.id,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
      });
      setDeleteStageModal(false);
      handleRefetchAfterDelete();
    },
  });

  useEffect(() => {
    fetchStageData({ variables: { filter: stageFilter } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetchStageDetails = () => {
    fetchStageData({ variables: { filter: { ...stageFilter } } });
  };

  useImperativeHandle(
    refetchRef,
    () => {
      return {
        refetchStageDetails() {
          refetchStageDetails();
        },
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const [reorderStage, { loading: reorderLoading }] = useMutation(
    REORDER_PROJECT_STAGE,
    {
      onCompleted() {
        Event(GA_EVENT.REORDER_PROJECT_CHECKLIST_STAGE, {
          label: GA_LABEL.REORDER_PROJECT_CHECKLIST_STAGE,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          project_checklist_id: eqcTypeId,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
        });
      },
      onError() {},
    },
  );

  const handleAddEditUser = (record) => {
    if (record) {
      const data = {
        ...record,
        pass: includes(
          record?.stageConfiguration,
          EQC_STAGE_CONFIG_LABEL.BYPASS,
        ),
        skip: includes(record?.stageConfiguration, EQC_STAGE_CONFIG_LABEL.SKIP),
      };
      setStageData(data);
    } else {
      setStageData();
    }
    setShowModal(true);
  };

  const handleTableChange = (pagination) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setStageFilter({ ...stageFilter, skip, limit: pagination.pageSize });
    setPaginationProp({ ...paginationProp, ...pagination });
    fetchStageData({
      variables: {
        filter: {
          ...stageFilter,
          skip,
          limit: pagination.pageSize,
        },
      },
    });
  };

  const handleEdit = (e, record) => {
    e?.stopPropagation?.();
    handleAddEditUser(record);
  };
  const handleDuplicate = (e, record) => {
    e?.stopPropagation?.();
    setShowDuplicateModal(true);
    setSelectedData(record);
  };

  const handleActiveAndDelete = (record) => {
    if (
      !record?.isActive &&
      activeStageCount === currentTenant?.tenant?.featureConfig?.stageMaxLimit
    ) {
      setShowUpgradeModal(true);
    } else {
      setDeleteStageModal(true);
      setSelectedData(record);
    }
  };

  const getLevelWiseApprovers = (record) => {
    const levelWiseApprovers = groupBy(record, (b) => {
      return b?.levelNumber;
    });
    return map(Object.keys(levelWiseApprovers), (data) => {
      return (
        <Descriptions.Item
          label={`${levelWiseApprovers?.[data]?.[0]?.levelName}:`}
          span={3}
        >
          {map(levelWiseApprovers?.[data], (approver) => {
            return (
              approver?.externalUserApprover?.name ||
              approver?.internalApprover?.user?.name
            );
          }).join(', ')}
        </Descriptions.Item>
      );
    });
  };
  const getApprovers = (record) => {
    const { projectEqcTypeStageApprovals } = record;

    return (
      <div>
        <div className="d-flex align-center">
          {projectEqcTypeStageApprovals?.length > 0 &&
            (isDesktopViewport ? (
              <CommonPopover
                overlayClassName="checklist-approvers-popup"
                getPopupContainer={() =>
                  // eslint-disable-next-line no-undef
                  document.querySelector('.eqc-type-details')
                }
                title="Approvers info:"
                trigger={isDesktopViewport ? 'hover' : 'click'}
                placement={isDesktopViewport ? 'right' : 'top'}
                destroyTooltipOnHide
                content={
                  <div onClick={(e) => e.stopPropagation()}>
                    <Descriptions
                      column={3}
                      layout="vertical"
                      colon={false}
                      className="inner"
                    >
                      {getLevelWiseApprovers(projectEqcTypeStageApprovals)}
                    </Descriptions>
                  </div>
                }
              >
                {projectEqcTypeStageApprovals?.length > 0 && (
                  <PersonDone
                    className="checklist-approvers mr-10"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  />
                )}
              </CommonPopover>
            ) : (
              <>
                {projectEqcTypeStageApprovals?.length > 0 && (
                  <PersonDone
                    className="checklist-approvers mr-10"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDrawerData(projectEqcTypeStageApprovals);
                      setOpen(true);
                    }}
                  />
                )}
              </>
            ))}
        </div>
      </div>
    );
  };

  const columns = [
    ...(isEditable
      ? [
          {
            width: '5%',
            align: 'center',
            className: 'drag-visible',
            render: (record) =>
              eqcTypeData?.project?.isActive &&
              isEditable &&
              record?.type === ACCESS_TYPE.INSPECTION && (
                <CommonTooltip title="Change order">
                  <div className="p-16">
                    <DragHandle />
                  </div>
                </CommonTooltip>
              ),
          },
        ]
      : []),
    {
      title: 'NAME',
      dataIndex: 'name',
      className: 'drag-visible',
      key: 'name',
      render: (text, record) => (
        <>
          {record?.rfiStage && (
            <>
              <div
                className={clsx(
                  'stage-wrapper',
                  record?.rfiStage?.id === stageId && 'fw-semi-bold',
                )}
                onClick={(e) => {
                  e.stopPropagation();
                  setTitle(record?.rfiStage?.name);
                  setStageId(record?.rfiStage?.id);
                }}
              >
                <div className="title">
                  <EllipsisText text={record?.rfiStage?.name} />
                </div>
                <div className="d-flex justify-end">
                  <>{getApprovers(record?.rfiStage)}</>
                </div>
              </div>
              <Divider />
            </>
          )}
          <div
            className={clsx(
              'stage-wrapper',
              record?.id === stageId && 'fw-semi-bold',
            )}
          >
            <div className="title">
              <EllipsisText text={text} />
            </div>
            <div className="d-flex justify-end">
              <div className="d-flex action-icons">
                <>{getApprovers(record)}</>
                {record?.type === ACCESS_TYPE.INSPECTION && isEditable && (
                  <>
                    {!includes(
                      [
                        PROJECT_CHECKLIST_STATUS.PUBLISHED,
                        PROJECT_CHECKLIST_STATUS.ARCHIVED,
                      ],
                      eqcTypeData?.projectEqcType?.status,
                    ) && (
                      <CommonTooltip trigger="focus" title="Edit">
                        <Button
                          shape="round"
                          icon={<EditButton />}
                          className="edit-button pointer b-0"
                          disabled={
                            eqcTypeData?.status ===
                              PROJECT_CHECKLIST_STATUS?.ARCHIVED ||
                            !eqcTypeData?.project?.isActive ||
                            !record?.isActive
                          }
                          onClick={(e) => handleEdit(e, record)}
                        />
                      </CommonTooltip>
                    )}
                    <CommonTooltip trigger="focus" title="Duplicate">
                      <Button
                        shape="round"
                        icon={<DuplicateIcon />}
                        disabled={
                          !record?.isActive ||
                          eqcTypeData?.status ===
                            PROJECT_CHECKLIST_STATUS?.ARCHIVED ||
                          !eqcTypeData?.project?.isActive
                        }
                        onClick={(e) => {
                          if (
                            activeStageCount >=
                            currentTenant?.tenant?.featureConfig?.stageMaxLimit
                          ) {
                            e?.stopPropagation?.();
                            setShowUpgradeModal(true);
                          } else {
                            handleDuplicate(e, record);
                          }
                        }}
                      />
                    </CommonTooltip>
                    {!includes(
                      [
                        PROJECT_CHECKLIST_STATUS.PUBLISHED,
                        PROJECT_CHECKLIST_STATUS.ARCHIVED,
                      ],
                      eqcTypeData?.projectEqcType?.status,
                    ) && (
                      <CommonTooltip trigger="focus" title="Delete">
                        <Button
                          className={clsx(
                            'delete-button pointer b-0',
                            (eqcTypeData?.status ===
                              PROJECT_CHECKLIST_STATUS?.PUBLISHED ||
                              eqcTypeData?.status ===
                                PROJECT_CHECKLIST_STATUS?.ARCHIVED ||
                              !eqcTypeData?.project?.isActive) &&
                              'disabled-button',
                          )}
                          shape="round"
                          icon={<DeleteButton />}
                          disabled={
                            eqcTypeData?.status ===
                              PROJECT_CHECKLIST_STATUS?.PUBLISHED ||
                            eqcTypeData?.status ===
                              PROJECT_CHECKLIST_STATUS?.ARCHIVED ||
                            !eqcTypeData?.project?.isActive
                          }
                          onClick={(e) => {
                            e?.stopPropagation?.();
                            handleActiveAndDelete(record);
                          }}
                        />
                      </CommonTooltip>
                    )}
                    {includes(
                      [
                        PROJECT_CHECKLIST_STATUS.PUBLISHED,
                        PROJECT_CHECKLIST_STATUS.ARCHIVED,
                      ],
                      eqcTypeData?.projectEqcType?.status,
                    ) && (
                      <Dropdown
                        menu={{
                          items: [
                            {
                              key: record?.isActive ? 'deactivate' : 'activate',
                              label: (
                                <span
                                  onClick={() => handleActiveAndDelete(record)}
                                >
                                  {record?.isActive ? 'Deactivate' : 'Activate'}
                                </span>
                              ),
                            },
                          ],
                        }}
                        trigger={['click']}
                        getPopupContainer={(triggerNode) =>
                          triggerNode?.parentNode
                        }
                      >
                        <Button
                          shape="round"
                          className="kebab-icon m-0"
                          icon={<KebabMenu />}
                          onClick={(e) => e.stopPropagation()}
                        />
                      </Dropdown>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      ),
    },
  ];

  const DraggableBodyRow = async ({ over, active }) => {
    if (!active || !over || active?.id === over?.id) return;
    const overIndex = over?.data?.current?.sortable?.index;
    const activeIndex = active?.data?.current?.sortable?.index;
    const data = [...dataSource];
    setDataSource(arrayMove(data, activeIndex, overIndex));
    try {
      const { errors } = await reorderStage({
        variables: {
          data: {
            stageIds: [active?.id, over?.id],
            projectEqcTypeId: Number(eqcTypeId),
          },
        },
      });
      if (errors) {
        throw errors;
      }
    } catch (error) {
      setDataSource(data);
    }
  };

  const getRowClassName = (record) => {
    let rowClassName = 'stage-row';
    if (
      (record?.id === stageId || record?.rfiStage?.id === stageId) &&
      record?.isActive
    )
      rowClassName += ' row-orange';
    if (!record?.isActive) rowClassName += ' row-disabled';
    return rowClassName;
  };

  return (
    <>
      <CanPerform
        action={ALLOWED_ACTION_KEYS.ADD_PROJECT_STAGE}
        type={ALLOWED_ACTION_TYPE.BOTH}
      >
        {showModal && (
          <AddEditStageModal
            showModal={showModal}
            setShowModal={setShowModal}
            stageData={stageData}
            isUpdate={!!stageData}
            setStageData={setStageData}
            refetchStageDataWithInitialValues={refetchStageDetails}
            eqcTypeId={eqcTypeId}
            eqcTypeData={eqcTypeData}
            isDisabled={
              // eqcTypeData?.status === PROJECT_CHECKLIST_STATUS?.PUBLISHED ||
              !eqcTypeData?.project?.isActive
            }
          />
        )}
        {showDuplicateModal && (
          <DuplicateEqcTypeModal
            showModal={showDuplicateModal}
            setShowModal={setShowDuplicateModal}
            refetchEqcTypeData={refetchStageDetails}
            projectEqcTypeStageId={selectedData?.id}
            title="Duplicate Checklist Stage"
          />
        )}
        {deleteStageModal && (
          <DeleteModalStage
            showModal={deleteStageModal}
            setShowModal={setDeleteStageModal}
            data={selectedData}
            mutation={
              !includes(
                [
                  PROJECT_CHECKLIST_STATUS.PUBLISHED,
                  PROJECT_CHECKLIST_STATUS.ARCHIVED,
                ],
                eqcTypeData?.projectEqcType?.status,
              )
                ? deleteStage
                : updateProjectEqcTypeStage
            }
            eqcTypeId={eqcTypeId}
            isDeletable={
              !includes(
                [
                  PROJECT_CHECKLIST_STATUS.PUBLISHED,
                  PROJECT_CHECKLIST_STATUS.ARCHIVED,
                ],
                eqcTypeData?.projectEqcType?.status,
              )
            }
          />
        )}
      </CanPerform>
      {eqcTypeData?.project?.isActive && isEditable && (
        <CanPerform
          action={ALLOWED_ACTION_KEYS.ADD_PROJECT_STAGE}
          type={ALLOWED_ACTION_TYPE.BOTH}
        >
          <Portal portalId="eqc-details-btn">
            <Portal portalId="add-btn">
              <Button
                shape="round"
                type="primary"
                id="add-btn"
                icon={<AddButton />}
                disabled={!eqcTypeData?.project?.isActive}
                className="add-button b-0"
                onClick={() => {
                  if (
                    activeStageCount <
                    currentTenant?.tenant?.featureConfig?.stageMaxLimit
                  ) {
                    handleAddEditUser();
                  } else {
                    setShowUpgradeModal(true);
                  }
                }}
              >
                Add
              </Button>
            </Portal>
          </Portal>
        </CanPerform>
      )}
      <div className="pointer">
        <DndContext
          modifiers={[restrictToVerticalAxis]}
          onDragEnd={DraggableBodyRow}
          sensors={sensors}
        >
          <SortableContext
            items={dataSource?.map((i) => i?.id) ?? []}
            strategy={verticalListSortingStrategy}
          >
            <CommonTable
              rowClassName={(record) => {
                return getRowClassName(record);
              }}
              onRow={(record) => {
                return {
                  onClick: () => {
                    if (record?.id) {
                      setTitle(record?.name);
                      setStageId(record?.id);
                    }
                  },
                };
              }}
              components={{ body: { row: Rows } }}
              columns={columns}
              dataSource={dataSource || []}
              onChange={handleTableChange}
              paginationConfig={paginationProp}
              rowKey="id"
              loading={loading || reorderLoading}
              className="checklist-row"
            />
          </SortableContext>
        </DndContext>
      </div>
      {open && !isDesktopViewport && (
        <ApproversDrawer open={open} setOpen={setOpen} height={350}>
          <div onClick={(e) => e.stopPropagation()}>
            <Descriptions
              column={3}
              layout="vertical"
              colon={false}
              className="inner"
            >
              {getLevelWiseApprovers(drawerData)}
            </Descriptions>
          </div>
        </ApproversDrawer>
      )}
      {showUpgradeModal && (
        <UpgradeModal
          showModal={showUpgradeModal}
          setShowModal={setShowUpgradeModal}
          projectId={projectId}
          featureKey={REQUEST_FEATURE_UPGRADE_KEYS.STAGE_MAX_LIMIT}
          isAlreadyRequested={
            currentTenant?.tenant?.featureApprovalRequestConfig
              ?.stageMaxLimitRequestSent
          }
        />
      )}
    </>
  );
};

export default StageListTable;
