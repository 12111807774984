//! many things in this files are commented due to future release tasks.
import { useMutation } from '@apollo/client';
import { Button, Form, Input, Modal, Popconfirm } from 'antd';
import clsx from 'clsx';
import { filter, findIndex, includes, values } from 'lodash';
import React, { useContext, useMemo, useState } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../AppContext';
import countryCodeData from '../../assets/countryCode.json';
import {
  ADD_USER_ROLES,
  BREAKPOINTS,
  GA_EVENT,
  GA_LABEL,
  MOBILE_NUMBER_NOTE,
  REGEX,
  USER_ROLES,
} from '../../common/constants';
import { Event } from '../../common/trackEvents';
import {
  formValidatorRules,
  getPhoneCodeFromTimezone,
  titleCase,
} from '../../common/utils';
import CommonSelect from '../../components/CommonSelect';
import PhoneNumberInputWithCountyCode from '../../components/PhoneNumberInputWithCountyCode';
import AddAgencyModal from '../agencies/AddAgencyModal';
import { CREATE_USER, UPDATE_USER } from './graphql/Mutations';

const { required, email, characterWithoutWhiteSpace } = formValidatorRules;

const AddUserModal = (props) => {
  const {
    isUpdate,
    showModal,
    setShowModal,
    userData,
    setUserData,
    refetchUserDataWithInitialValues,
  } = props;
  const isTabletViewport = useMedia(`(min-width: ${BREAKPOINTS.tablet}px)`);
  const { getCurrentUser, getTenantUser } = useContext(AppContext);
  const [current, setCurrent] = useState(0);
  // const [step1formDetails, setStep1formDetails] = useState();
  const [agencyList, setAgencyList] = useState([]);
  const currentUser = getCurrentUser();
  const currentTenant = getTenantUser();
  const [showAgencyModal, setSetShowAgencyModal] = useState(false);
  const [agencyData, setAgencyData] = useState();
  const [showChecklistMakerNote, setShowChecklistMakerNote] = useState(false);
  const [form] = Form.useForm();

  const [createUserMutate, { loading: createUserLoading }] = useMutation(
    CREATE_USER,
    {
      onError() {},
      onCompleted() {
        Event(GA_EVENT.ADD_GLOBAL_USER, {
          label: GA_LABEL.ADD_GLOBAL_USER,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
        });
        setUserData();
        form.resetFields();
        setShowModal(false);
        refetchUserDataWithInitialValues();
      },
    },
  );

  const [updateUserMutate, { loading: updateUserLoading }] = useMutation(
    UPDATE_USER,
    {
      onError() {},
      onCompleted() {
        Event(GA_EVENT.EDIT_GLOBAL_USER, {
          label: GA_LABEL.EDIT_GLOBAL_USER,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          updated_user_id: userData?.id,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
        });
        setUserData();
        form.resetFields();
        setShowModal(false);
        refetchUserDataWithInitialValues();
      },
    },
  );

  const handleCancel = () => {
    setShowModal(false);
    form.resetFields();
    setUserData();
  };

  useMemo(() => {
    if (currentTenant?.tenant?.timeZone && !userData?.countryCode) {
      const phoneCode = getPhoneCodeFromTimezone(
        currentTenant?.tenant?.timeZone,
      );
      form.setFieldsValue({
        countryCode: phoneCode,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTenant?.tenant?.timeZone]);

  const onUserSubmitFinish = async (formValues) => {
    // if (current === 0) {
    //   const newFormValues = {
    //     ...formValues,
    //     phoneNo: `${formValues?.countryCode}${formValues?.phoneNo}`,
    //     country: filter(
    //       countryCodeData,
    //       (record) => record?.dialCode === formValues?.countryCode,
    //     )?.[0]?.countryCode?.toUpperCase(),
    //   };
    //   setStep1formDetails(newFormValues);
    //   setCurrent(1);
    //   form.setFieldsValue({
    //     agencyIds: map(userData?.agencies, (record) => record?.id),
    //   });
    //   return;
    // }
    const newFormValues = {
      // ...step1formDetails,
      ...formValues,
      phoneNo: `${formValues?.countryCode}${formValues?.phoneNo}`,
      country: filter(
        countryCodeData,
        (record) => record?.dialCode === formValues?.countryCode,
      )?.[0]?.countryCode?.toUpperCase(),
      agencyData: agencyList,
    };

    const variables = isUpdate
      ? { data: newFormValues, id: userData?.id }
      : newFormValues;

    try {
      if (isUpdate) {
        updateUserMutate({
          variables: { ...variables },
        });
        return;
      }
      createUserMutate({
        variables: { data: { ...variables } },
      });
    } catch (error) {
      return error;
    }
  };

  const handleAdd = () => {
    setShowModal(true);
    form.submit();
  };
  const handleSelect = (value) => {
    if (isUpdate) {
      if (
        !includes(userData?.roles, ADD_USER_ROLES.CHECKLIST_MAKER) &&
        value === ADD_USER_ROLES.CHECKLIST_MAKER
      ) {
        setShowChecklistMakerNote(true);
      } else {
        setShowChecklistMakerNote(false);
      }
    }
  };

  // const handleEdit = (user) => {
  //   setSetShowAgencyModal(true);
  //   setAgencyData(user);
  // };

  const step1Form = (
    <>
      <Form.Item
        rules={[
          required,
          characterWithoutWhiteSpace,
          {
            max: 250,
            message: 'Username cannot be more than 250 characters',
          },
        ]}
        name="name"
        label="Name"
      >
        <Input allowClear placeholder="Enter Name" />
      </Form.Item>
      <Form.Item
        rules={[{ required, message: 'Required' }]}
        name="roles"
        label="Roles"
      >
        <CommonSelect
          className="mr-3"
          placeholder="Select Role"
          allowClear
          showSearch
          optionFilterProp="label"
          onChange={handleSelect}
          disabled={includes(userData?.roles, USER_ROLES.SYSTEM_ADMIN)}
          options={[
            ...(includes(userData?.roles, USER_ROLES.SYSTEM_ADMIN)
              ? {
                  key: USER_ROLES.SYSTEM_ADMIN,
                  value: USER_ROLES.SYSTEM_ADMIN,
                  label: titleCase(USER_ROLES.SYSTEM_ADMIN),
                }
              : []),
            ...values(ADD_USER_ROLES).map((role) => {
              return {
                key: role,
                value: role,
                label: titleCase(role),
              };
            }),
          ]}
        />
      </Form.Item>
      <Form.Item rules={[required, email]} name="email" label="Email">
        <Input allowClear placeholder="Enter Email" />
      </Form.Item>
      <Form.Item
        label={
          <>
            <span className="required-mark">*</span> Mobile Number
          </>
        }
        name="phoneNo"
        dependencies={['countryCode']}
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!getFieldValue('countryCode')) {
                return Promise.reject(new Error('Please Select Country Code'));
              }
              if (!value) {
                return Promise.reject(new Error('Enter Mobile Number'));
              }
              if (value && !value.match(REGEX.PHONE)) {
                return Promise.reject(
                  new Error('should be a valid contact number'),
                );
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <PhoneNumberInputWithCountyCode />
      </Form.Item>
      <div className="text-danger note-text note-margin ">
        Note : {MOBILE_NUMBER_NOTE}
      </div>
      {showChecklistMakerNote && (
        <div className="text-danger mb-20">
          <b>Caution:</b> Changing role to Checklist Maker will remove this user
          from all the assigned projects
        </div>
      )}
      {/* <div className="form-buttons">
        <Button
          shape="round"
          type="primary"
          className="save-button"
          htmlType="submit"
        >
          Next
        </Button>
      </div> */}
    </>
  );
  // const step2Form = (
  //   <>
  //     <>
  //       <Form.Item
  //         name="agencyIds"
  //         label={
  //           <div className="d-flex justify-between width-percent-100">
  //             <div>Select Agency</div>
  //           </div>
  //         }
  //         rules={[
  //           {
  //             required: agencyList?.length < 1,
  //             message: 'This field is required!',
  //           },
  //         ]}
  //       >
  //         <CommonDropdown
  //           allowClear
  //           showSearch
  //           mode="multiple"
  //           placeholder="Select Agency"
  //           optionFilterProp="children"
  //           query={GLOBAL_AGENCY_DROPDOWN_LIST}
  //           fetchPolicy="network-only"
  //           responsePath="globalAgencyDropdownList.data"
  //           valuePath="id"
  //           labelPath="name"
  //           optionKey="contact-users"
  //           showArrow
  //           showExtraLoader
  //         />
  //       </Form.Item>
  //       <Divider dashed />
  //     </>
  //     <div className="create-agency-title">Create Agency</div>
  //     {agencyList?.length > 0 &&
  //       map(agencyList, (user, index) => (
  //         <>
  //           <Row
  //             gutter={10}
  //             align="middle"
  //             justify="space-between"
  //             className="mt-15"
  //             key={index}
  //           >
  //             <Col span={22}>
  //               <Card className="user-card">
  //                 <Row justify="space-between" align="middle">
  //                   <Col span={20}>
  //                     <EllipsisText text={user?.name} />
  //                   </Col>
  //                   <Col span={2}>
  //                     <CommonTooltip
  //                       trigger="focus"
  //                       title="Edit"
  //                       placement="bottom"
  //                     >
  //                       <Button
  //                         shape="round"
  //                         className="b-0"
  //                         icon={<EditButton height={28} width={28} />}
  //                         onClick={() => handleEdit(user)}
  //                       />
  //                     </CommonTooltip>
  //                   </Col>
  //                 </Row>
  //               </Card>
  //             </Col>
  //             <Col span={2}>
  //               <Popconfirm
  //                 title="Are you sure you want to remove this agency?"
  //                 onConfirm={() => {
  //                   agencyList?.splice(index, index + 1);
  //                   setAgencyList([...agencyList]);
  //                 }}
  //                 okText="Yes"
  //                 cancelText="No"
  //               >
  //                 <CommonTooltip
  //                   trigger="focus"
  //                   title="Remove"
  //                   placement="bottom"
  //                 >
  //                   <Button className="b-0" icon={<MinusCircle />} />
  //                 </CommonTooltip>
  //               </Popconfirm>
  //             </Col>
  //           </Row>
  //           {filter(
  //             map(agencyList, 'name'),
  //             (data) => data?.toLowerCase() === user?.name?.toLowerCase(),
  //           )?.length > 1 && (
  //             <Row>
  //               <div className="text-danger mt-5">
  //                 Agency name already exists
  //               </div>
  //             </Row>
  //           )}
  //         </>
  //       ))}
  //     <Button
  //       type="link"
  //       className="mt-15"
  //       icon={<PlusOutlined />}
  //       onClick={() => {
  //         setSetShowAgencyModal(true);
  //       }}
  //     >
  //       Add Agency
  //     </Button>
  //   </>
  // );
  const renderFrom = {
    0: step1Form,
    // 1: step2Form,
  };
  return (
    <div id="add-edit-user">
      {showAgencyModal && (
        <AddAgencyModal
          showModal={showAgencyModal}
          setShowModal={setSetShowAgencyModal}
          agencyData={agencyData}
          isUpdate={!!agencyData}
          setAgencyData={setAgencyData}
          setAgencyList={setAgencyList}
          agencyList={agencyList}
          onlyGetAgencyData
          agencyId={findIndex(agencyList, agencyData)}
        />
      )}
      <Modal
        maskClosable={false}
        centered
        open={showModal}
        closable={false}
        confirmLoading={createUserLoading || updateUserLoading}
        onOk={handleAdd}
        className="dialog"
        okText={isUpdate ? 'Save' : 'Add'}
        onCancel={handleCancel}
        // closeIcon={<WrongIcon />}
        footer={null}
        getContainer={() =>
          // eslint-disable-next-line no-undef
          document.getElementById('add-edit-user')
        }
      >
        <h2>{isUpdate ? 'Edit user' : 'Add user'}</h2>
        <div
          className={clsx(
            'd-flex align-center',
            isTabletViewport && 'justify-center',
          )}
        >
          {/* <Steps
            className="mb-24 mt-5"
            current={current}
            items={[
              ...map(keys(ADD_USER_STEPS), (stepItem) => {
                return {
                  key: stepItem,
                  title: ADD_USER_STEPS[stepItem],
                  id: `step-${stepItem}`,
                };
              }),
            ]}
          /> */}
        </div>
        <Form
          form={form}
          initialValues={
            userData
              ? {
                  ...userData,
                  phoneNo: userData?.phoneNo?.replace(
                    userData?.countryCode,
                    '',
                  ),
                }
              : { isActive: true, countryCode: '+91' }
          }
          layout="vertical"
          onFinish={onUserSubmitFinish}
        >
          {renderFrom[current]}
          {current === 0 && (
            <div className="form-buttons">
              <Button
                shape="round"
                className="cancel-button"
                onClick={() => {
                  if (current === 1) {
                    setCurrent(0);
                  } else {
                    handleCancel();
                  }
                }}
              >
                {current === 1 ? 'Previous' : 'Cancel'}
              </Button>
              <Popconfirm
                title="You are about to update user information. Are you sure?"
                okText="Yes"
                cancelText="No"
                disabled={!isUpdate}
                okButtonProps={{ shape: 'round' }}
                cancelButtonProps={{ shape: 'round' }}
                placement="topRight"
                onConfirm={() => {
                  form.submit();
                }}
              >
                <Button
                  shape="round"
                  className="save-button"
                  type="primary"
                  onClick={() => {
                    if (!isUpdate) {
                      form.submit();
                    }
                  }}
                >
                  {isUpdate ? 'Save' : 'Add'}
                </Button>
              </Popconfirm>
            </div>
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default AddUserModal;
