import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Dropdown, Tag, message } from 'antd';
import clsx from 'clsx';
import { forEach, includes, map, nth, slice } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../../AppContext';
import {
  AddButton,
  DownArrow,
  DuplicateIcon,
  EditButton,
  Export,
  Import,
  InfoIcon,
  KebabMenu,
} from '../../../assets/svg';
import {
  BREAKPOINTS,
  CSV_SAMPLE_FILES,
  DEFAULT_PAGE_SIZE,
  GA_EVENT,
  GA_LABEL,
  USER_LIST_ACTIONS,
  USER_ROLES,
} from '../../../common/constants';
import { Event } from '../../../common/trackEvents';
import { copyToClipboard, titleCase } from '../../../common/utils';
import CommonCard from '../../../components/CommonCard';
import CommonImportModal from '../../../components/CommonImportModal';
import CommonPopover from '../../../components/CommonPopover';
import CommonTable from '../../../components/CommonTable';
import CommonTooltip from '../../../components/CommonTooltip';
import EllipsisText from '../../../components/EllipsisText';
import InfiniteScrollHandler from '../../../components/InfiniteScrollHandler';
import Portal from '../../../components/Portal';
import SearchComponent from '../../../components/SearchComponent';
import AddUserModal from '../AddUserModal';
import DeActiveUserModal from '../DeActiveUserModal';
import DeleteModal from '../DeleteModal';
import ReplaceReplicateModal from '../ReplaceReplicateModal';
import ReplaceUserModal from '../ReplaceUserModal';
import { IMPORT_USER_CSV, UPDATE_STATUS } from '../graphql/Mutations';
import { GET_USERS } from '../graphql/Queries';

const getCountInfo = () => {
  return (
    <CommonPopover
      destroyTooltipOnHide
      getPopupContainer={() =>
        // eslint-disable-next-line no-undef
        document.querySelector('.user-count')
      }
      placement="bottom"
      overlayClassName="count-popover"
      content={
        <div onClick={(e) => e.stopPropagation()}>
          <h4>User's counts are displayed for all involved projects:</h4>
          <ul>
            <li>
              <b>Inspections:</b> Indicates the count of pass, fail, bypass and
              skip.
            </li>
            <li>
              <b>Instructions:</b> Indicates the count of raised issues.
            </li>
            <li>
              <b>Approval Given:</b> Indicates the count of approvals and redos.
            </li>
          </ul>
        </div>
      }
    >
      <InfoIcon height={18} width={18} className="info-icon ml-5" />
    </CommonPopover>
  );
};

const getUserInfoComponent = (record) => {
  return (
    <>
      <div className="user-info-div mb-8 d-flex justify-between align-center">
        <div className="d-flex">
          Email:&nbsp; <EllipsisText text={record?.email} />
        </div>
        <div>
          <DuplicateIcon
            height={28}
            width={28}
            className="ml-5"
            onClick={() => {
              copyToClipboard(record?.email, 'Email copied!');
            }}
          />
        </div>
      </div>
      <div className="user-info-div d-flex align-center justify-between">
        <div>Phone: {record?.phoneNo}</div>
        <DuplicateIcon
          height={28}
          width={28}
          onClick={() => {
            copyToClipboard(record?.phoneNo, 'Phone number copied!');
          }}
        />
      </div>
    </>
  );
};
const UserList = () => {
  const [showImportModal, setShowImportModal] = useState(false);
  const [showReplaceModal, setShowReplaceModal] = useState(false);
  const { getToken, getCurrentUser, getCurrentTenant, getTenantUser } =
    useContext(AppContext);
  const currentUser = getCurrentUser();
  const token = getToken();
  const tenantId = getCurrentTenant() || getTenantUser()?.tenant?.id;

  const url = `${process.env.REACT_APP_REPORT_SERVER_URL}/api/v1/export-csv/users?access_token=Bearer ${token}&tenantId=${tenantId}`;
  const sampleFileUrl = CSV_SAMPLE_FILES.USER_CSV;
  const list = ['Name', 'Email', 'Unique Phone Without County Code'];
  const initialUserFilter = {
    skip: 0,
    limit: 10,
    sortBy: { field: 'createdAt', order: 'DESC' },
  };

  const initialPaginationValue = {
    total: 0,
    current: 1,
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [showStatusModal, setStatusModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [showReplaceReplicateModal, setShowReplaceReplicateModal] =
    useState(false);
  const [userData, setUserData] = useState();
  const [actionType, setActionType] = useState();
  const [userListData, setUserListData] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [mutationId, setMutationId] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [title, setTitle] = useState('');
  const [hasMore, setHasMore] = useState(true);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [activeUserCount, setActiveUserCount] = useState(0);
  const [totalUserCount, setTotalUserCount] = useState(0);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const isTabletViewport = useMedia(`(min-width: ${BREAKPOINTS.tablet}px)`);

  const [userFilter, setUserFilter] = useState(initialUserFilter);

  const [fetchUserData, { loading }] = useLazyQuery(GET_USERS, {
    variables: { filter: userFilter },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const data = res?.userList?.data;
      setActiveUserCount(res?.userList?.activeUserCount);
      setTotalUserCount(res?.userList?.totalUserCount);
      const pagination = {
        ...paginationProp,
        total: res?.userList?.total,
      };
      if (scrollFlag) {
        const datalist = [...userListData, ...data];
        setUserListData(datalist);
        setScrollFlag(false);
      } else {
        const datalist = [...data];
        setUserListData(datalist);
      }
      setHasMore(!!data?.length);
      setPaginationProp(pagination);
    },
    onError() {},
  });

  const [updateStatus, { loading: mutationLoading }] = useMutation(
    UPDATE_STATUS,
    {
      onCompleted: () => {
        Event(GA_EVENT.CHANGE_GLOBAL_USER_STATUS, {
          label: GA_LABEL.CHANGE_GLOBAL_USER_STATUS,
          // eslint-disable-next-line no-undef,
          pathname: window?.location?.href,
          updated_user_id: mutationId,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
        });
        fetchUserData({ variables: { filter: userFilter } });
        setStatusModal(false);
      },
      onError() {},
    },
  );

  const [updateUserImport, { data: importData, loading: importLoading }] =
    useMutation(IMPORT_USER_CSV, { onError() {} });

  useEffect(() => {
    fetchUserData({ variables: { filter: userFilter } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetchUserDetails = () => {
    fetchUserData({ variables: { filter: { ...userFilter } } });
  };

  const handleAddEditUser = () => {
    setShowModal(true);
  };

  const handleImport = () => {
    setShowImportModal(true);
  };

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setUserFilter({
        ...userFilter,
        skip,
        limit: pagination.pageSize,
        sortBy: {
          field: sorter.columnKey,
          order: sorter.order === 'ascend' ? 'ASC' : 'DESC',
        },
      });
      fetchUserData({
        variables: {
          filter: {
            ...userFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: {
              field: sorter.columnKey,
              order: sorter.order === 'ascend' ? 'ASC' : 'DESC',
            },
          },
        },
      });
    } else {
      setUserFilter({
        ...userFilter,
        skip,
        limit: pagination.pageSize,
        sortBy: { field: 'createdAt', order: 'DESC' },
      });
      fetchUserData({
        variables: {
          filter: {
            ...userFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: { field: 'createdAt', order: 'DESC' },
          },
        },
      });
    }
  };

  const ActiveStatus = ({ record }) => {
    if (record?.isActive === true) {
      return (
        <>
          <Tag className="active-tag">Active</Tag>
          {record?.lastUpdated && (
            <span className="user-status-time">
              {`${moment(record?.lastUpdated).from(moment(), true)} `}
              ago
            </span>
          )}
        </>
      );
    }
    return (
      <>
        <Tag className="inactive-tag">Inactive</Tag>
        {record?.lastUpdated && (
          <span className="user-status-time">
            since
            {` ${moment(record?.lastUpdated).from(moment(), true)} `}
          </span>
        )}
      </>
    );
  };

  const handleActiveStatus = () => {
    setStatusModal(true);
  };

  const handleChange = (record, value, type) => {
    setMutationId(record?.id);
    setIsDisabled(value);
    setTitle(value ? 'Activate' : 'Deactivate');
    if (
      record?.isActive &&
      record?.assignedAsApprover &&
      type === USER_LIST_ACTIONS.DEACTIVATE
    ) {
      setShowDeactivateModal(true);
      setUserData(record);
    } else if (
      includes([USER_LIST_ACTIONS.REPLACE, USER_LIST_ACTIONS.REPLICATE], type)
    ) {
      if (record?.projectUsers?.length > 0 || record?.assignedAsApprover) {
        setShowReplaceReplicateModal(true);
        setUserData(record);
      } else {
        message.destroy();
        message.error(
          `This user cannot be ${type} as no checklists, approvals or RFI is assigned to this user`,
        );
      }
    } else {
      handleActiveStatus();
    }
  };

  const getActionButtons = (record) => {
    const handleEdit = (e) => {
      e?.stopPropagation?.();
      setUserData(record);
      handleAddEditUser();
    };
    return (
      <div className="d-flex align-center">
        <CommonTooltip trigger="focus" title="Edit">
          <Button
            shape="round"
            icon={<EditButton />}
            onClick={handleEdit}
            disabled={includes(record?.roles, USER_ROLES.SYSTEM_ADMIN)}
            className={clsx(
              'edit-button pointer',
              includes(record?.roles, USER_ROLES.SYSTEM_ADMIN) &&
                'disabled-button',
            )}
          />
        </CommonTooltip>
        <div className="d-flex action-icons">
          <Dropdown
            getPopupContainer={() =>
              // eslint-disable-next-line no-undef
              document.querySelector('.users-table')
            }
            menu={{
              items: [
                //! commented for future use
                // {
                //   key: USER_LIST_ACTIONS.REPLACE,
                //   disabled: !record?.projectUsers?.length > 0,
                //   label: (
                //     <div
                //       onClick={() => {
                //         setActionType(USER_LIST_ACTIONS.REPLACE);
                //         handleChange(record, false, USER_LIST_ACTIONS.REPLACE);
                //       }}
                //     >
                //       Replace
                //     </div>
                //   ),
                // },
                {
                  key: USER_LIST_ACTIONS.REPLICATE,
                  disabled:
                    !(
                      record?.projectUsers?.length > 0 ||
                      record?.assignedAsApprover
                    ) || !record?.isActive,
                  label: (
                    <div
                      onClick={() => {
                        if (record?.isActive) {
                          setActionType(USER_LIST_ACTIONS.REPLICATE);
                          handleChange(
                            record,
                            false,
                            USER_LIST_ACTIONS.REPLICATE,
                          );
                        }
                      }}
                    >
                      Replicate
                    </div>
                  ),
                },
                ...(record?.isActive === true
                  ? [
                      {
                        key: USER_LIST_ACTIONS.DEACTIVATE,
                        disabled: includes(
                          record?.roles,
                          USER_ROLES.SYSTEM_ADMIN,
                        ),
                        label: (
                          <div
                            className={clsx(
                              includes(record?.roles, USER_ROLES.SYSTEM_ADMIN)
                                ? 'disabled-button'
                                : 'text-danger',
                            )}
                            onClick={() => {
                              if (
                                !includes(
                                  record?.roles,
                                  USER_ROLES.SYSTEM_ADMIN,
                                )
                              ) {
                                setActionType(USER_LIST_ACTIONS.DEACTIVATE);
                                handleChange(
                                  record,
                                  false,
                                  USER_LIST_ACTIONS.DEACTIVATE,
                                );
                              }
                            }}
                          >
                            Deactivate
                          </div>
                        ),
                      },
                    ]
                  : [
                      {
                        key: 'activate',
                        label: (
                          <div
                            className="text-success"
                            onClick={() => handleChange(record, true)}
                          >
                            Activate
                          </div>
                        ),
                      },
                    ]),
              ],
            }}
            trigger={['click']}
          >
            <Button
              shape="round"
              className="kebab-icon m-0"
              icon={<KebabMenu />}
              onClick={(e) => e.stopPropagation()}
              // className={clsx(
              //   'edit-button pointer',
              //   includes(record?.roles, USER_ROLES.SYSTEM_ADMIN) &&
              //     'disabled-button',
              // )}
            />
          </Dropdown>
        </div>
      </div>
    );
  };
  const getAssignedProjects = (record) => {
    const fetchName = [];
    if (record?.projectUsers?.length !== 0) {
      forEach(record?.projectUsers, (data) => {
        fetchName.push(data?.project?.name);
      });
    }
    const selectedName = nth(fetchName, 0);
    if (fetchName?.length === 1) {
      return (
        <CommonTooltip
          title={selectedName}
          placement={isDesktopViewport ? 'right' : 'top'}
        >
          {selectedName}
        </CommonTooltip>
      );
    }
    if (fetchName?.length > 1) {
      return (
        <CommonTooltip
          title={slice(fetchName, 1, fetchName?.length).join(', ')}
          placement={isDesktopViewport ? 'right' : 'top'}
          getTooltipContainer={() =>
            // eslint-disable-next-line no-undef
            document.querySelector('.users-table')
          }
        >
          {`${selectedName} and +${fetchName?.length - 1}`}
        </CommonTooltip>
      );
    }

    return '-';
  };
  // commented for future release
  // const getAssignedAgencies = (record) => {
  //   const fetchName = [];
  //   if (record?.agencies?.length !== 0) {
  //     forEach(record?.agencies, (data) => {
  //       fetchName.push(data?.name);
  //     });
  //   }
  //   const selectedName = nth(fetchName, 0);
  //   if (fetchName?.length === 1) {
  //     return (
  //       <CommonTooltip
  //         title={selectedName}
  //         placement={isDesktopViewport ? 'right' : 'top'}
  //       >
  //         {selectedName}
  //       </CommonTooltip>
  //     );
  //   }
  //   if (fetchName?.length > 1) {
  //     return (
  //       <CommonTooltip
  //         title={slice(fetchName, 1, fetchName?.length).join(', ')}
  //         placement={isDesktopViewport ? 'right' : 'top'}
  //         getTooltipContainer={() =>
  //           // eslint-disable-next-line no-undef
  //           document.querySelector('.users-table')
  //         }
  //       >
  //         {`${selectedName} and +${fetchName?.length - 1}`}
  //       </CommonTooltip>
  //     );
  //   }

  //   return '-';
  // };

  const getUserActivityCount = (record) => {
    return (
      <div
        className={clsx('user-activity-count', isDesktopViewport && 'd-flex')}
      >
        <div className="title">
          Inspections:{' '}
          <span className="count">{record?.inspectionCount || 0}</span>
        </div>
        <div className={clsx('title', isDesktopViewport && ' ml-10')}>
          Instructions:{' '}
          <span className="count">{record?.instructionCount || 0}</span>
        </div>
        <div className={clsx('title', isDesktopViewport && ' ml-10')}>
          Approval given:{' '}
          <span className="count">{record?.approvalGivenCount || 0}</span>
        </div>
      </div>
    );
  };
  const getUserInfo = (name, record) => {
    return (
      <div>
        <CommonPopover
          placement={isTabletViewport ? 'right' : 'bottom'}
          overlayClassName="user-info-popover"
          content={getUserInfoComponent(record)}
          trigger={isDesktopViewport ? 'hover' : 'click'}
          destroyTooltipOnHide
          getPopupContainer={() => {
            // eslint-disable-next-line no-undef
            document.querySelector('.users-table');
          }}
        >
          <div className="d-flex align-center">
            <div>
              <EllipsisText text={name} />
            </div>
            <div>
              <DownArrow className="ml-5 down-arrow" width={10} height={10} />
            </div>
          </div>
        </CommonPopover>
      </div>
    );
  };

  const columns = [
    {
      title: '#',
      key: 'id',
      render: (text, record, index) => {
        return <div>{userFilter?.skip + index + 1}</div>;
      },
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      sorter: !loading,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
      render: (name, record) => getUserInfo(name, record),
    },
    {
      title: 'ACTIVE',
      key: 'isActive',
      sorter: !loading,
      sortOrder: sortedInfo?.columnKey === 'isActive' && sortedInfo?.order,
      render: (activeStatus, record) => {
        return <ActiveStatus record={record} />;
      },
    },
    {
      title: 'DEFAULT ROLE',
      dataIndex: 'roles',
      key: 'roles',
      sorter: !loading,
      sortOrder: sortedInfo?.columnKey === 'roles' && sortedInfo?.order,
      render: (role) => titleCase(role) || '-',
    },
    // commented for future release
    // {
    //   title: 'TEAM',
    //   key: 'agencies',
    //   render: (text, record) => getAssignedAgencies(record),
    // },
    {
      title: 'PROJECTS ASSIGNED',
      key: 'projects',
      render: (text, record) => getAssignedProjects(record),
    },
    {
      title: (
        <div className="d-flex align-center user-count">
          COUNT {getCountInfo()}
        </div>
      ),
      dataIndex: 'phoneNo',
      key: 'phoneNo',
      render: (_, record) => getUserActivityCount(record),
    },
    {
      title: 'ACTIONS',
      render: (action, record) => getActionButtons(record),
    },
  ];

  const onSearchChange = async (value) => {
    setUserFilter({ ...userFilter, skip: 0, search: value });
    setPaginationProp(initialPaginationValue);
    fetchUserData({
      variables: { filter: { ...userFilter, skip: 0, search: value } },
    });
  };
  const handleRefetch = () => {
    fetchUserData({
      variables: {
        filter: {
          ...userFilter,
          skip: userListData?.length,
          limit: DEFAULT_PAGE_SIZE,
          sortBy: { field: 'createdAt', order: 'DESC' },
        },
      },
    });
  };
  return (
    <>
      {showModal && (
        <AddUserModal
          showModal={showModal}
          setShowModal={setShowModal}
          setUserData={setUserData}
          userData={userData}
          isUpdate={!!userData}
          refetchUserDataWithInitialValues={refetchUserDetails}
        />
      )}
      {showReplaceReplicateModal && (
        <ReplaceReplicateModal
          showModal={showReplaceReplicateModal}
          setShowModal={setShowReplaceReplicateModal}
          userRecord={userData}
          refetchUserDetails={refetchUserDetails}
          setUserData={setUserData}
          actionType={actionType}
        />
      )}
      {showDeactivateModal && (
        <DeActiveUserModal
          showModal={showDeactivateModal}
          setShowModal={setShowDeactivateModal}
          userRecord={userData}
          refetchUserDetails={refetchUserDetails}
          setUserData={setUserData}
        />
      )}

      {showStatusModal && (
        <DeleteModal
          showModal={showStatusModal}
          setShowModal={setStatusModal}
          title={title}
          updateUserStatus={updateStatus}
          mutationId={mutationId}
          setMutationId={setMutationId}
          isDisabled={isDisabled}
          loading={mutationLoading}
          name="User"
          subtitle="User"
        />
      )}
      {showImportModal && (
        <CommonImportModal
          showImportModal={showImportModal}
          setShowImportModal={setShowImportModal}
          title={
            importData?.importUserCsv?.invalidDataCount > 0
              ? 'User Status'
              : 'Users'
          }
          sampleFileUrl={sampleFileUrl}
          list={list}
          updateUserImport={updateUserImport}
          importLoading={importLoading}
          refetchData={fetchUserData}
          filePrefix="userCsv"
          className="checklist-row"
        />
      )}
      <Portal portalId="search-component">
        <SearchComponent
          id="search-container-id"
          name="Users"
          getData={onSearchChange}
        />
      </Portal>
      <Portal portalId="sub-header">
        <h5 className={clsx('mt-5', !isDesktopViewport ? 'ml-16' : '')}>
          Active Users: {activeUserCount} out of {totalUserCount}
        </h5>
      </Portal>
      <Portal portalId="user-import-export-buttons">
        <Portal portalId="add-button">
          <Button
            shape="round"
            id="add-btn"
            icon={!isDesktopViewport && <AddButton />}
            onClick={handleAddEditUser}
            className="mr-5"
          >
            {isDesktopViewport && 'Add'}
          </Button>
        </Portal>
        <Button shape="round" icon={<Import />} onClick={handleImport}>
          {isDesktopViewport && 'Import'}
        </Button>
        <Button
          shape="round"
          target="_blank"
          icon={<Export />}
          href={url}
          className="export-button"
          onClick={() =>
            Event(GA_EVENT.EXPORT_GLOBAL_USER, {
              label: GA_LABEL.EXPORT_GLOBAL_USER,
              // eslint-disable-next-line no-undef
              pathname: window?.location?.href,
              user_id: currentUser?.id,
              user_name: currentUser?.name,
              tenant_id: currentUser?.tenantUser?.tenant?.id,
              tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
            })
          }
        >
          {isDesktopViewport && 'Export'}
        </Button>
      </Portal>

      {isDesktopViewport ? (
        <div className="users-table">
          <CommonTable
            rowClassName={(record) => {
              if (!record?.isActive) return 'deactivated-color';
            }}
            loadingData={loading}
            columns={columns}
            data={userListData || []}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
            rowKey={(obj) => obj?.id}
          />
        </div>
      ) : (
        <InfiniteScrollHandler
          scrollFlag={scrollFlag}
          loading={loading}
          refetchData={handleRefetch}
          setScrollFlag={setScrollFlag}
          hasMore={hasMore}
          dataLength={userListData?.length}
          skeletonRows={columns?.length - 2}
        >
          <div className="users-table">
            {map(userListData, (user, index) => {
              return (
                <CommonCard key={user?.id}>
                  <div className="common-card d-flex">
                    <div className="mr-5 fw-medium">{index + 1}.</div>
                    <div>
                      <div className="card-header fw-medium">
                        <span>{getUserInfo(user?.name, user)}</span>
                      </div>
                      <div className="card-content text-secondary">
                        <br />
                        <div className="mb-15">
                          <span className="fw-medium mr-5">Status:</span>
                          <ActiveStatus record={user} />
                        </div>
                        <div className="mb-15">
                          <span className="fw-medium mr-5">Default Role:</span>
                          {titleCase(user?.roles) || '-'}
                        </div>
                        {/* commented for future release */}
                        {/* <div className="d-flex align-center">
                          <span className="fw-medium mr-5">Team:</span>
                          {getAssignedAgencies(user)}
                        </div> */}
                        <div className="d-flex align-center">
                          <span className="fw-medium mr-5">
                            Project Assigned:
                          </span>
                          {getAssignedProjects(user)}
                        </div>
                        <div className="d-flex align-center user-count">
                          <span className="fw-medium mr-5">
                            Count: {getCountInfo()}
                          </span>
                          {getUserActivityCount(user)}
                        </div>
                      </div>
                    </div>
                    <span className="d-flex position-absolute user-action-btn">
                      {getActionButtons(user)}
                    </span>
                  </div>
                </CommonCard>
              );
            })}
          </div>
        </InfiniteScrollHandler>
      )}
      {showReplaceModal && (
        <ReplaceUserModal
          setShowModal={setShowReplaceModal}
          showModal={showReplaceModal}
          // approverData={approverData}
          // replacedApproverData={replacedApproverData}
          // setReplacedApproverData={setReplacedApproverData}
          // setReplacedApprovers={setReplacedApprovers}
          // replacedApprovers={replacedApprovers}
          // isUpdate={filter(
          //   replacedApproverData,
          //   (record) => record?.id === approverData?.id,
          // )}
        />
      )}
    </>
  );
};

export default UserList;
