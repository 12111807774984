import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, DatePicker, Form, Input, message, Row } from 'antd';
import dayjs from 'dayjs';
import { camelCase, findIndex, get, isNaN, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../../../AppContext';
import { ForwardArrow, ProjectIcon } from '../../../../../../assets/svg';
import {
  DEFAULTDATEFORMAT,
  PROPERTY_TYPES,
  ROUTES,
  TAB_KEYS,
} from '../../../../../../common/constants';
import useRouter from '../../../../../../common/useRouter';
import { formValidatorRules } from '../../../../../../common/utils';
import CommonSelect from '../../../../../../components/CommonSelect';
import { CREATE_NEW_SET_PROPERTY } from '../../../../graphql/Mutation';
import { GET_SINGLE_PROJECT } from '../../../../graphql/Queries';

const { characterWithoutWhiteSpace, number } = formValidatorRules;

const renderPropertyUi = (data) => {
  const disabledDate = (current) => {
    return current && current > dayjs().endOf('day');
  };

  return map(data, (item) => {
    if (item?.type === PROPERTY_TYPES.TEXT) {
      return (
        <Form.Item
          label={item?.name}
          name={camelCase(item?.name)}
          rules={[
            { required: item?.isRequired, message: 'This field is required!' },
            characterWithoutWhiteSpace,
            { max: 250, message: 'Name cannot be more than 250 characters' },
          ]}
        >
          <Input placeholder="Enter Value" allowClear />
        </Form.Item>
      );
    }

    if (item?.type === PROPERTY_TYPES.NUMERIC) {
      return (
        <Form.Item
          label={item?.name}
          name={camelCase(item?.name)}
          rules={[
            { required: item?.isRequired, message: 'This field is required!' },
            number,
          ]}
        >
          <Input placeholder="Enter number" allowClear />
        </Form.Item>
      );
    }

    if (item?.type === PROPERTY_TYPES.DATE) {
      return (
        <Form.Item
          label={item?.name}
          name={camelCase(item?.name)}
          rules={[
            { required: item?.isRequired, message: 'This field is required!' },
          ]}
        >
          <DatePicker
            className="width-percent-100"
            allowClear
            disabledDate={disabledDate}
            format={DEFAULTDATEFORMAT}
          />
        </Form.Item>
      );
    }

    if (item?.type === PROPERTY_TYPES.OPTIONS) {
      const options = map(item?.options, (option) => ({
        label: option,
        value: option,
      }));

      return (
        <Form.Item
          label={item?.name}
          name={camelCase(item?.name)}
          rules={[
            { required: item?.isRequired, message: 'This field is required!' },
          ]}
        >
          <CommonSelect
            options={options}
            allowClear
            placeholder="Select Option"
          />
        </Form.Item>
      );
    }

    if (item?.type === PROPERTY_TYPES.MULTI_LINE_TEXT) {
      return (
        <Form.Item
          label={item?.name}
          name={camelCase(item?.name)}
          rules={[
            { required: item?.isRequired, message: 'This field is required!' },
          ]}
        >
          <Input.TextArea rows={4} allowClear />
        </Form.Item>
      );
    }
    if (item?.type === PROPERTY_TYPES.CHECKBOXES) {
      const options = map(item?.options, (option) => ({
        label: option,
        value: option,
      }));
      return (
        <Form.Item
          label={
            <div className="d-flex justify-between width-percent-100">
              <div>{item?.name}</div>
            </div>
          }
          name={camelCase(item?.name?.split(' ')?.join(''))}
          rules={[
            { required: item?.isRequired, message: 'This field is required!' },
          ]}
        >
          <CommonSelect
            options={options}
            mode="multiple"
            placeholder="Select Options"
            showSearch
          />
        </Form.Item>
      );
    }
  });
};

const AddSetsProperty = () => {
  const { getCurrentUser, dispatch } = useContext(AppContext);
  const currentUserId = getCurrentUser()?.id || {};
  const [form] = Form.useForm();
  const {
    navigate,
    location,
    params: { projectId },
  } = useRouter();
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const registerDetailsData = location?.state?.registerDetailsData;
  const registerProperty = location?.state?.registerProperty;
  const navFilter = location?.state?.navFilter;
  const navPagination = location?.state?.navPagination;
  const registerId = registerDetailsData?.id;
  const registerName = camelCase(
    registerDetailsData?.name?.split(' ')?.join(''),
  );

  const [getWebProject, { data: projectData }] = useLazyQuery(
    GET_SINGLE_PROJECT,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        dispatch({
          type: 'SET_PROJECT_DETAILS',
          data: JSON.stringify(res?.getProject),
        });
        const index = findIndex(
          res?.getProject?.projectUsers,
          (user) => Number(user?.userId) === Number(currentUserId),
        );
        if (index !== -1) {
          const loggedInUserRole =
            res?.getProject?.projectUsers?.[index]?.roles;
          dispatch({ type: 'SET_PROJECT_ROLE', data: loggedInUserRole });
        } else {
          dispatch({ type: 'SET_PROJECT_ROLE', data: '' });
        }
      },
    },
  );

  const [createNewSetProperty] = useMutation(CREATE_NEW_SET_PROPERTY, {
    fetchPolicy: 'network-only',
    onError() {},
    onCompleted() {},
  });

  const onFinish = async () => {
    try {
      const formValues = form.getFieldsValue();

      const result = map(registerProperty, (item) => {
        const key = camelCase(item?.name);
        const value = Array.isArray(formValues[key])
          ? formValues[key].join(',')
          : formValues[key];

        if (value === undefined || value === '') return null;

        return {
          registerDetailId: item?.id,
          value,
        };
      }).filter(Boolean);

      const response = await createNewSetProperty({
        variables: {
          registerId,
          data: { registerDetailValues: result },
        },
      });

      const registerLogId = get(
        response,
        'data.createRegisterLog.registerLogId',
      );

      navigate(
        `${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.REGISTER}/${registerId}/${registerLogId}`,
        {
          state: {
            registerDetailsData,
            registerId,
            registerName,
            registerLogId,
          },
        },
      );
    } catch (error) {
      message.destroy();
      message.error('Something went wrong.');
      return error;
    }
  };

  useEffect(() => {
    if (!isNaN(Number(projectId))) {
      getWebProject({
        variables: {
          id: projectId,
        },
      });
    }
  }, [projectId]);

  const handleCancel = () => {
    form.resetFields();
    navigate(-1);
  };

  return (
    <div className="add-project-wrapper">
      <div className="project-details-title d-flex justify-between page-header">
        <div className="project-details-title-text d-flex align-center">
          <ProjectIcon
            onClick={() => {
              navigate(ROUTES.PROJECTS);
            }}
            className="pointer"
          />
          <ForwardArrow />
          <h1
            className="pointer mr-10 text-primary"
            onClick={() => {
              navigate(`${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.REGISTER}`, {
                state: {
                  navFilter,
                  navPagination,
                },
              });
            }}
          >
            {projectData?.getProject?.name}
          </h1>
          <ForwardArrow />
          <h1 className="mr-10">Add Set</h1>
        </div>
      </div>
      <div className="project-module mb-20">
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Card>
            <h3>Add Details</h3>
            {renderPropertyUi(
              registerProperty,
              isSelectedAll,
              setIsSelectedAll,
              form,
            )}
          </Card>
          <Row justify="end" className="mt-10">
            <div className="form-buttons">
              <Button shape="round" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                shape="round"
                type="primary"
                className="save-button"
                htmlType="submit"
              >
                Create
              </Button>
            </div>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default AddSetsProperty;
